import React from "react";
import Logo from "../../assets/logo.png";
import CallIcon from "../../assets/icons/icon_phone.png";
import AddressIcon from "../../assets/icons/icon_location.png";
import EmailIcon from "../../assets/icons/icon_mail.png";
import { footerInfo, footerMenuItems } from "../utils/app.content";

export function Footer() {
  const menuItems = footerMenuItems;
  const info = footerInfo;

  return (
    <footer className="bg-black text-white">
      {/* Main Div */}
      <div className="container mx-auto py-4 px-4 lg:px-20">
        <div className="flex flex-wrap justify-center py-8 text-xs md:text-md xl:text-lg">
          {/* Logo */}
          <img src={Logo} alt="" />
          {/* Menu */}
          <div className="w-full flex justify-center py-4 ">
            {footerMenuItems.map((item, index) => {
              return (
                <a key={index} href={item.route}>
                  <p className="flex-initial px-1">
                    {index != footerMenuItems.length - 1
                      ? `${item.name} |`
                      : item.name}
                  </p>
                </a>
              );
            })}
          </div>
          {/* Contact */}
          <div className="w-full flex flex-wrap lg:flex-nowrap justify-evenly py-4 ">
            <div className="flex flex-nowrap md:flex-wrap lg:flex-nowrap md:justify-center md:text-center lg:text-left w-full md:w-1/3 p-3 md:p-0">
              <div className="flex items-center">
                <img
                  src={CallIcon}
                  alt=""
                  className="bg-[#D40801] p-3 rounded-full"
                />
              </div>
              <div className="px-4 py-3 w-full">
                <h3 className="font-bold">Call Support</h3>
                <p>{info.phone}</p>
              </div>
            </div>
            <div className="flex flex-nowrap md:flex-wrap lg:flex-nowrap md:justify-center md:text-center lg:text-left w-full md:w-1/3 p-3 md:p-0">
              <div className="flex items-center">
                <img
                  src={AddressIcon}
                  alt=""
                  className="bg-[#0077C0] p-3 rounded-full"
                />
              </div>
              <div className="px-4 py-3">
                <h3 className="font-bold">Address</h3>
                <p>{info.address}</p>
              </div>
            </div>
            <div className="flex flex-nowrap md:flex-wrap lg:flex-nowrap md:justify-center md:text-center lg:text-left w-full md:w-1/3 p-3 md:p-0">
              <div className="flex items-center">
                <img
                  src={EmailIcon}
                  alt=""
                  className="bg-[#D40801] p-3 rounded-full block mx-auto"
                />
              </div>
              <div className="px-4 py-3 w-full">
                <h3 className="font-bold">Email</h3>
                <p>{info.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* All Rights Reserved */}
      <div className="w-full text-center py-4 border-t border-white border-solid">
        <p>© 2023 Tradespeople.space - All rights reserved. </p>
      </div>
    </footer>
  );
}
