import { IRoute } from "../../common/typings/routes";
import { PostJob } from "./pages/PostJob";

export const PostJobRoute: IRoute = {
  title: "Job Listing",
  path: "/post-job",
  component: PostJob,
  isAuthGuard: false,
  isNoAuthGuard: false
};
