import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import {
  homeSearchString,
  paginationControlsJobs,
  setJobsList,
  setJobsPaginationControls,
  setShowViewSection,
  viewSection,
} from "../../../app-redux/main/mainSlice";
import { Banner } from "./Banner";
import { JobsList } from "./JobsList";
import iconSort from "../../../assets/icons/icon-sort.png";
import iconSortArrow from "../../../assets/icons/icon-sort-arrow.png";
import iconGrid from "../../../assets/icons/icon-grid.png";
import iconList from "../../../assets/icons/icon-list.png";
import imgCommunity from "../../../assets/img-join-community.png";
import ReactPaginate from "react-paginate";
import { CommonInputField } from "../../../common/components/CommonInputField";
import { ViewJob } from "./ViewJob";
import DialogBox from "../../../common/components/CommonDialog";
import { FavoriteJobsList } from "./FavoriteJobsList";
import { useFormik } from "formik";
import { Chat } from "../../chat/Chat";
import { ax } from "../../../common/api/apiClient";

export function JobsMain() {
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(paginationControlsJobs);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [searchValue, setsearchValue] = useState(0);

  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const showViewSection = useAppSelector(viewSection);
  const searchString = useAppSelector(homeSearchString);

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: {
      keyword: searchString || "",
    },
    onSubmit: async (values: any) => {
      getJobs();
    },
  });

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    getJobs();
  }, [offset, sortBy, searchValue]);

  const handleSearch = (val: any) => {
    setsearchValue(val);
  };

  const getJobs = async () => {
    const payload = {
      userId: localStorage.getItem("userId") || 0,
      limit: 5,
      offset: offset,
      searchString: formik.values.keyword || "",
      order: sortBy,
    };

    try {
      const res: any = await ax.post(`/jobs/all`, payload);
      if (res && res.data.statusCode == 200) {
        dispatch(setJobsList(res.data.value || []));
        dispatch(setJobsPaginationControls(res.data.pagination));
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const handleSort = (sortBy: string) => {
    setSortBy(sortBy);
  };

  const handlePageClick = (event: any) => {
    const newOffset =
      (event.selected * pagination.itemsPerPage) % pagination.noOfRecords;
    setOffset(newOffset);
  };

  const handleCloseDialog = () => {
    setShowDialog(false); // Hide the dialog box
  };

  const tabStyles = "px-6 py-3 mx-1 rounded-b-2xl focus:outline-none w-1/3";
  const activeTabStyles = "bg-[#CD171F] text-white";
  const inactiveTabStyles = "bg-gray-200 text-gray-700";

  return (
    <>
      <DialogBox
        isOpen={showDialog}
        header={header}
        message={message}
        onClose={handleCloseDialog}
      />
      {showViewSection ? (
        <ViewJob />
      ) : (
        <>
          <div>
            <div className="flex">
              <button
                className={`${tabStyles} ${
                  activeTab === "all" ? activeTabStyles : inactiveTabStyles
                }`}
                onClick={() => handleTabClick("all")}
              >
                All Jobs List
              </button>
              <button
                className={`${tabStyles} ${
                  activeTab === "fav" ? activeTabStyles : inactiveTabStyles
                }`}
                onClick={() => handleTabClick("fav")}
              >
                Favorite Jobs List
              </button>
              <button
                className={`${tabStyles} ${
                  activeTab === "chat" ? activeTabStyles : inactiveTabStyles
                }`}
                onClick={() => handleTabClick("chat")}
              >
                Chat
              </button>
            </div>

            <div className="border border-gray-200 py-6 mt-4 rounded-b-lg">
              {activeTab === "all" && (
                <div>
                  {/* <Banner /> */}
                  {/* Pagination controls */}
                  <div className="w-full lg:w-3/4 rounded-2xl md:rounded-full flex flex-wrap lg:flex-nowrap mx-auto my-12 py-3 px-6 border border-gray-400 border-solid">
                    <div className="flex w-full xl:w-1/2">
                      <div
                        className="flex py-3 px-4 cursor-pointer w-1/2"
                        onClick={() => {
                          handleSort("id");
                        }}
                      >
                        <img
                          src={iconSort}
                          alt=""
                          className="py-1 pr-2 xl:px-3  mt-0 mb-auto"
                        />
                        <p>Sort by id</p>
                        {/* <img src={iconSortArrow} alt="" className="px-1 py-2" /> */}
                      </div>
                      <p className="text-4xl text-gray-300">|</p>
                      <div
                        className="flex py-3 px-4 cursor-pointer w-1/2"
                        onClick={() => {
                          handleSort("name");
                        }}
                      >
                        <img
                          src={iconSort}
                          alt=""
                          className="py-1 pr-2 xl:px-3  mt-0 mb-auto"
                        />
                        <p>Sort by name</p>
                        {/* <img src={iconSortArrow} alt="" className="px-1 py-2" /> */}
                      </div>
                      <p className="text-4xl text-gray-300">|</p>
                    </div>
                    <div className="flex py-3 pl-3 xl:px-10 justify-between w-full ">
                      {" "}
                      {/* md:w-3/5 xl:w-2/3 */}
                      <p>
                        Showing{" "}
                        {pagination.noOfRecords == 0
                          ? 0
                          : pagination.offset + 1}{" "}
                        -{" "}
                        {pagination.offset + pagination.itemsPerPage >
                        pagination.noOfRecords
                          ? pagination.noOfRecords
                          : pagination.offset + pagination.itemsPerPage}{" "}
                        of {pagination.noOfRecords} results
                      </p>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pagination.pageCount}
                        previousLabel=""
                        renderOnZeroPageCount={null}
                        className="w-1/2 flex justify-evenly"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap justify-center">
                    <CommonInputField
                      innerClass="m-2 py-2 px-6 rounded-full text-black border border-gray-400"
                      type="text"
                      name="keyword"
                      placeholder="Keyword"
                      value={formik.values.keyword}
                      onChange={(event: any) => {
                        formik.setFieldValue("keyword", event.target.value);
                      }}
                    />
                    <CommonInputField
                      innerClass="bg-[#0077C0] m-2 py-2 px-10 rounded-full cursor-pointer text-white"
                      type="button"
                      value="SEARCH"
                      onClick={() => {
                        formik.submitForm();
                      }}
                    />
                  </div>

                  <JobsList
                    redirectedFrom="jobs"
                    searchValue={searchValue}
                    handleSearch={handleSearch}
                  />
                </div>
              )}

              {activeTab === "fav" && (
                <div>
                  <FavoriteJobsList />
                </div>
              )}

              {activeTab === "chat" && (
                <div>
                  {/* Section 3 Content */}
                  <Chat redirectedFrom="homeowners" />
                </div>
              )}

              {/* Join Community Section */}
              <div className="relative w-full">
                <div
                  className="bg-[#CD171F] bg-cover bg-center text-white py-32 lg:py-40 mt-4"
                  style={{ backgroundImage: `url(${imgCommunity})` }}
                >
                  <div className="py-6 px-6 lg:pl-40 lg:pr-28 w-full lg:w1/2 xl:w-2/3">
                    <p className="w-full flex justify-center text-4xl lg:text-6xl py-3">
                      Join our community of talented professionals by applying
                      for a job today!
                    </p>
                    <p className="w-full flex text-lg lg:text-xl mt-8">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                    <CommonInputField
                      innerClass="bg-black my-8 py-2 px-10 rounded-full cursor-pointer text-white"
                      type="button"
                      value="Discover More"
                      onClick={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
