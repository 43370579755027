import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import {
  allJobsList,
  setEngineersList,
  setJobsList,
} from "../../../app-redux/main/mainSlice";
import bgPhoto from "../../../assets/featured-bg.png";
import { JobsList } from "../../jobs/pages/JobsList";
import { EngineersList } from "../../engineers/pages/EngineersList";
import DialogBox from "../../../common/components/CommonDialog";
import { ax } from "../../../common/api/apiClient";

export function Featured() {
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");

  useEffect(() => {
    localStorage.getItem("userType") && localStorage.getItem("userType") == "0"
      ? getFeaturedJobs()
      : getFeaturedEngineers();
  }, []);

  const getFeaturedJobs = async () => {
    try {
      const res: any = await ax.get(`/jobs/featured`);
      if (res && res.data.statusCode == 200) {
        dispatch(setJobsList(res.data.value || []));
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const getFeaturedEngineers = async() => {
    try {
      const res: any = await ax.get(`/engineers/featured`);
      if (res && res.data.statusCode == 200) {
        dispatch(setEngineersList(res.data.value || []));
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false); // Hide the dialog box
  };

  return (
    <>
      <DialogBox
        isOpen={showDialog}
        header={header}
        message={message}
        onClose={handleCloseDialog}
      />
      <div
        className="flex flex-wrap px-2 py-12 md:p-12 bg-no-repeat"
        style={{
          backgroundImage: `url(${bgPhoto})`,
          backgroundPosition: "right bottom",
        }}
      >
        <p className="w-full text-center text-lg">Looking for one of these?</p>
        <h1 className="w-full text-center text-4xl font-bold py-4">
          {localStorage.getItem("userType") &&
          localStorage.getItem("userType") == "0"
            ? "Featured Jobs"
            : "Featured Tradepersons"}
        </h1>

        {localStorage.getItem("userType") &&
        localStorage.getItem("userType") == "0" ? (
          <JobsList redirectedFrom="featured" />
        ) : (
          <EngineersList redirectedFrom="featured" />
        )}
      </div>
    </>
  );
}
