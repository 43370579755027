import React, { useEffect, useState } from "react";
import { CommonInputField } from "../../../../common/components/CommonInputField";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import {
    registerFormData,
  setRegisterFormData,
  setRegistrationStep,
  stepNo,
} from "../../../../app-redux/auth/authSlice";
import { object, string } from "yup";

export function Step2() {
  const step = useAppSelector(stepNo);
  const data = useAppSelector(registerFormData);
  const dispatch = useAppDispatch();
  const [selectedStep2Option, setSelectedStep2Option] = useState("1");
  const validationSchema = object({
    tradingName: string().required("This field is required"),
    registeredCompanyName: selectedStep2Option == "2" || selectedStep2Option == "4" ? string().required("This field is required") : string(),
    companyRegistrationNumber: selectedStep2Option == "2" || selectedStep2Option == "4" ? string().required("This field is required") : string(),
    partnersFullNames: selectedStep2Option == "3" ? string().required("This field is required") : string(),
  })
  
  const formik = useFormik<any>({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      tradingName: data.tradingName || "",
      registeredCompanyName: data.registeredCompanyName || "",
      companyRegistrationNumber: data.companyRegistrationNumber || "",
      partnersFullNames: data.partnersFullNames || "",
    },
    onSubmit: async (values: any) => {
      const payload: any = {
        ...data,
        ...values,
        trading: selectedStep2Option,
      };
      dispatch(setRegisterFormData(payload));
      dispatch(setRegistrationStep(step + 1));
    },
  });

  useEffect(() => {
    setSelectedStep2Option(data.trading || "1")
  }, [data])

  const handleStep2OptionChange = (event: any) => {
    setSelectedStep2Option(event.target.value);
  };

  return (
    <div>
      <p className="text-3xl font-bold mb-4">About you</p>
      <div className="py-2">You operate as a:</div>
      {/* Radio Button options */}
      <div className="flex flex-col px-3 border border-gray-300 border-solid rounded-sm">
        <label className="inline-flex items-center cursor-pointer py-3 border-b-2">
          <CommonInputField
            type="radio"
            value="1"
            checked={selectedStep2Option === "1"}
            onChange={handleStep2OptionChange}
            innerClass="form-radio"
          />
          <span className="ml-2">Self-employed / Sole Trader</span>
        </label>

        <label className="inline-flex items-center cursor-pointer py-3 border-b-2">
          <CommonInputField
            type="radio"
            value="2"
            checked={selectedStep2Option === "2"}
            onChange={handleStep2OptionChange}
            innerClass="form-radio"
          />
          <span className="ml-2">Limited Company</span>
        </label>

        <label className="inline-flex items-center cursor-pointer py-3 border-b-2">
          <CommonInputField
            type="radio"
            value="3"
            checked={selectedStep2Option === "3"}
            onChange={handleStep2OptionChange}
            innerClass="form-radio"
          />
          <span className="ml-2">Ordinary Partnership</span>
        </label>

        <label className="inline-flex items-center cursor-pointer py-3">
          <CommonInputField
            type="radio"
            value="4"
            checked={selectedStep2Option === "4"}
            onChange={handleStep2OptionChange}
            innerClass="form-radio"
          />
          <span className="ml-2">Limited Partnership</span>
        </label>
      </div>

      {/* Trading Name */}
      {selectedStep2Option == "1" ? (
        <div className="pt-3">
          This can just be your name if you don't have one
        </div>
      ) : (
        <></>
      )}
      <CommonInputField
        outerClass="w-full py-3"
        innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
        type="text"
        name="tradingName"
        placeholder="Trading Name"
        value={formik.values.tradingName}
        error={formik.touched.tradingName && Boolean(formik.errors.tradingName)}
        helperText={formik.touched.tradingName && formik.errors.tradingName}
        onChange={(event: any) => {
          formik.setFieldValue("tradingName", event.target.value);
        }}
      />
      {/* Other option fields */}
      {selectedStep2Option == "2" || selectedStep2Option == "4" ? (
        <div>
          <CommonInputField
            outerClass="w-full py-3"
            innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
            type="text"
            name="registeredCompanyName"
            placeholder="Registered company name"
            value={formik.values.registeredCompanyName}
            error={formik.touched.registeredCompanyName && Boolean(formik.errors.registeredCompanyName)}
            helperText={formik.touched.registeredCompanyName && formik.errors.registeredCompanyName}
            onChange={(event: any) => {
              formik.setFieldValue("registeredCompanyName", event.target.value);
            }}
          />
          <CommonInputField
            outerClass="w-full py-3"
            innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
            type="text"
            name="companyRegistrationNumber"
            placeholder="Company registration number"
            value={formik.values.companyRegistrationNumber}
            error={formik.touched.companyRegistrationNumber && Boolean(formik.errors.companyRegistrationNumber)}
            helperText={formik.touched.companyRegistrationNumber && formik.errors.companyRegistrationNumber}
            onChange={(event: any) => {
              formik.setFieldValue(
                "companyRegistrationNumber",
                event.target.value
              );
            }}
          />
        </div>
      ) : selectedStep2Option == "3" ? (
        <div>
          <div className="py-2">
            Comma-separated list of all names on the partnership agreement,
            including yourself (e.g. John Smith, Jane Doe)
          </div>
          <CommonInputField
            outerClass="w-full py-3"
            innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
            type="text"
            name="partnersFullNames"
            placeholder="Full names of all partners"
            value={formik.values.partnersFullNames}
            error={formik.touched.partnersFullNames && Boolean(formik.errors.partnersFullNames)}
            helperText={formik.touched.partnersFullNames && formik.errors.partnersFullNames}
            onChange={(event: any) => {
              formik.setFieldValue("partnersFullNames", event.target.value);
            }}
          />
        </div>
      ) : (
        <></>
      )}

      {/* buttons */}
      <div className="inline-flex w-full">
        <CommonInputField
          outerClass="w-2/5 px-2 py-3 flex justify-center"
          innerClass="py-3 px-6 rounded-full border border-[#D40801] text-[#D40801] w-full text-xl font-bold cursor-pointer"
          type="button"
          value="Back"
          onClick={() => {
            dispatch(setRegistrationStep(step - 1));
          }}
        />
        <CommonInputField
          outerClass="w-3/5 px-2 py-3 flex justify-center"
          innerClass="py-3 px-6 rounded-full bg-[#D40801] w-full text-white text-xl font-bold cursor-pointer"
          type="button"
          value="Continue"
          onClick={() => {
            formik.submitForm();
          }}
        />
      </div>
    </div>
  );
}
