import React, { useEffect, useState } from "react";
import { CommonInputField } from "../../../../common/components/CommonInputField";
import { object, string } from "yup";
import { useFormik } from "formik";
import DialogBox from "../../../../common/components/CommonDialog";
import { ax } from "../../../../common/api/apiClient";

export function AccountSettingsPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  const [user, setUser] = useState<any>();

  const validationSchemaEngineer = object({
    firstName: string().required("This field is required"),
    lastName: string().required("This field is required"),
    phoneNo: string().required("This field is required"),
    email: string().required("This field is required"),
    password: string().required("This field is required"),
    workAddress: string().required("This field is required"),
    workAddress2: string().required("This field is required"),
    town: string().required("This field is required"),
    postcode: string().required("This field is required"),
    distance: string().required("This field is required"),
    intro: string().required("This field is required"),
  });

  const validationSchemaHomeowner = object({
    firstName: string().required("This field is required"),
    lastName: string().required("This field is required"),
    phoneNo: string().required("This field is required"),
    email: string().required("This field is required"),
    password: string().required("This field is required"),
  });

  const formik = useFormik<any>({
    enableReinitialize: true,
    validationSchema: localStorage.getItem("userType") == "0" ? validationSchemaEngineer : validationSchemaHomeowner,
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      phoneNo: "",
      email: "",
      password: "",
      workAddress: "",
      workAddress2: "",
      town: "",
      postcode: "",
      distance: "",
      intro: "",
    },
    onSubmit: async (values: any) => {
      try {
        const res: any = await ax.patch(`/users/update`, values);
        if (res.data.statusCode == 200) {
          setShowDialog(true);
          setHeader("Success");
          setMessage(res.data.message);
        }
      } catch (error: any) {
        setShowDialog(true);
        setHeader("Error");
        setMessage(error.response.data.message);
      }
    },
  });

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    if (!localStorage.getItem("token")) {
      setShowDialog(true);
      setHeader("Error");
      setMessage("Please login first");
      // history.push("/login");
      return;
    }

    try {
      const id = localStorage.getItem("userId");
      const res: any = await ax.get(`/users/view/${id}`);
      if (res && res.data.statusCode == 200) {
        setUser({ ...res.data.value[0] });
        formik.setValues({ ...res.data.value[0] });
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseDialog = () => {
    setShowDialog(false); // Hide the dialog box
  };

  return (
    <>
      <DialogBox
        isOpen={showDialog}
        header={header}
        message={message}
        onClose={handleCloseDialog}
      />
      <div className="mx-auto my-16 w-full lg:w-2/5 px-3 lg:px-0">
        {/* Form */}
        <div className="bg-white p-6 shadow-lg">
          <p className="text-3xl font-bold mb-4 px-2 text-center">
            Change Account Settings
          </p>
          <div className="flex flex-wrap">
            {/* First Name */}
            <CommonInputField
              outerClass="w-full px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formik.values.firstName}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              onChange={(event: any) => {
                formik.setFieldValue("firstName", event.target.value);
              }}
            />

            {/* Last Name */}
            <CommonInputField
              outerClass="w-full px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              onChange={(event: any) => {
                formik.setFieldValue("lastName", event.target.value);
              }}
            />

            {/* Phone No */}
            <CommonInputField
              outerClass="w-full px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="phoneNo"
              placeholder="Phone No"
              value={formik.values.phoneNo}
              error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
              helperText={formik.touched.phoneNo && formik.errors.phoneNo}
              onChange={(event: any) => {
                formik.setFieldValue("phoneNo", event.target.value);
              }}
            />

            {/* Email */}
            <CommonInputField
              outerClass="w-full px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="email"
              placeholder="Email"
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onChange={(event: any) => {
                formik.setFieldValue("email", event.target.value);
              }}
            />

            {/* Password */}
            <CommonInputField
              outerClass="w-full px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onChange={(event: any) => {
                formik.setFieldValue("password", event.target.value);
              }}
              isPasswordField={true}
              showPassword={showPassword}
              handleTogglePassword={handleTogglePassword}
            />

            {user && user.userType == "0" && (
              <div className="w-full">
                {/* Work Address */}
                {
                  <CommonInputField
                    outerClass="w-full px-2 py-3"
                    innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
                    type="text"
                    name="workAddress"
                    placeholder="Work Address"
                    value={formik.values.workAddress}
                    error={
                      formik.touched.workAddress &&
                      Boolean(formik.errors.workAddress)
                    }
                    helperText={
                      formik.touched.workAddress && formik.errors.workAddress
                    }
                    onChange={(event: any) => {
                      formik.setFieldValue("workAddress", event.target.value);
                    }}
                  />
                }

                {/* Work Address 2 */}
                <CommonInputField
                  outerClass="w-full px-2 py-3"
                  innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
                  type="text"
                  name="workAddress2"
                  placeholder="Work Address 2"
                  value={formik.values.workAddress2}
                  error={
                    formik.touched.workAddress2 &&
                    Boolean(formik.errors.workAddress2)
                  }
                  helperText={
                    formik.touched.workAddress2 && formik.errors.workAddress2
                  }
                  onChange={(event: any) => {
                    formik.setFieldValue("workAddress2", event.target.value);
                  }}
                />

                {/* Town */}
                <CommonInputField
                  outerClass="w-full px-2 py-3"
                  innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
                  type="text"
                  name="town"
                  placeholder="Town"
                  value={formik.values.town}
                  error={formik.touched.town && Boolean(formik.errors.town)}
                  helperText={formik.touched.town && formik.errors.town}
                  onChange={(event: any) => {
                    formik.setFieldValue("town", event.target.value);
                  }}
                />

                {/* Postcode */}
                <CommonInputField
                  outerClass="w-full px-2 py-3"
                  innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
                  type="text"
                  name="postcode"
                  placeholder="Postcode"
                  value={formik.values.postcode}
                  error={
                    formik.touched.postcode && Boolean(formik.errors.postcode)
                  }
                  helperText={formik.touched.postcode && formik.errors.postcode}
                  onChange={(event: any) => {
                    formik.setFieldValue("postcode", event.target.value);
                  }}
                />

                {/* Distance */}
                <CommonInputField
                  outerClass="w-full py-3"
                  innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
                  type="number"
                  name="distance"
                  value={formik.values.distance}
                  error={
                    formik.touched.distance && Boolean(formik.errors.distance)
                  }
                  helperText={formik.touched.distance && formik.errors.distance}
                  onChange={(event: any) => {
                    formik.setFieldValue("distance", event.target.value);
                  }}
                />

                {/* Intro */}
                <textarea
                  name="intro"
                  cols={30}
                  rows={4}
                  className={
                    formik.touched.intro && Boolean(formik.errors.intro)
                      ? "w-full m-2 p-2 border border-red-600 border-solid rounded-sm"
                      : "w-full m-2 p-2 border border-gray-300 border-solid rounded-sm"
                  }
                  value={formik.values.intro}
                  onChange={(event: any) => {
                    formik.setFieldValue("intro", event.target.value);
                  }}
                ></textarea>
                {formik.touched.intro && Boolean(formik.errors.intro) ? (
                  <div className="text-red-600 mt-1 ml-6">
                    This field is required
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            <CommonInputField
              outerClass="w-full px-2 py-3 flex justify-center"
              innerClass="py-3 px-6 rounded-full bg-[#D40801] w-full md:w-2/3 xl:w-2/5 text-white text-xl font-bold cursor-pointer"
              type="button"
              value="Update"
              onClick={() => {
                console.log("submitting", formik)
                formik.submitForm();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
