import { HomeRoute } from "../modules/home/home.routes";
import { JobRoute } from "../modules/jobs/jobs.routes";
import { PostJobRoute } from "../modules/post-job/post-job.routes";
import { RegisterRoute } from "../modules/auth/register/register.routes";
import { LoginRoute } from "../modules/auth/login/login.routes";
import { EngineersRoute } from "../modules/engineers/engineers.routes";
import { RegisterHomeownerRoute } from "../modules/auth/register-homeowner/register.routes";
import { AccountSettingsRoute } from "../modules/auth/change-account-settings/account-settings.routes";

export const ROUTES = [HomeRoute, JobRoute, PostJobRoute, RegisterRoute, RegisterHomeownerRoute, LoginRoute, EngineersRoute, AccountSettingsRoute];
