import React, { useEffect, useState } from "react";
import { CommonInputField } from "../../../../common/components/CommonInputField";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import {
  registerFormData,
  setRegisterFormData,
  setRegistrationStep,
  stepNo,
} from "../../../../app-redux/auth/authSlice";
import { FaSearch } from "react-icons/fa";
import { allTradesList } from "../../../../common/utils/app.content";

export function Step5() {
  const step = useAppSelector(stepNo);
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(allTradesList);
  const [checkedCount, setCheckedCount] = useState(0);
  const [checkedValues, setCheckedValues] = useState<any>([]);
  const data = useAppSelector(registerFormData);

  useEffect(() => {
    setCheckedValues(
      data.selectedTrades.map((item: any) => {
        return item.id;
      })
    );
    setCheckedCount(data.selectedTrades.length);
  }, [data]);

  const handleInputChange = (event: any) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);

    const filteredResults = allTradesList.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const handleCheckboxChange = (isChecked: boolean, value: string) => {
    const newCount = isChecked ? checkedCount + 1 : checkedCount - 1;

    if (newCount <= 5) {
      setCheckedCount(newCount);
      setCheckedValues((prevValues: any) => {
        if (isChecked) {
          return [...prevValues, value];
        } else {
          return prevValues.filter((item: any) => item !== value);
        }
      });
    }
  };

  return (
    <div>
      <p className="text-3xl font-bold mb-4">Select up to 5 trades</p>
      <div className="py-2">
        Select trades to suit your business. Once your application has been
        approved you can go for more.
      </div>

      {/* Search field */}
      <div className="flex items-center rounded-full text-black bg-[#F7F7F7] w-full mb-4">
        <div className="p-4">
          <FaSearch />
        </div>
        <CommonInputField
          outerClass="w-full py-3"
          innerClass="bg-[#F7F7F7] w-full outline-none"
          type="text"
          name="address"
          placeholder="Search"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </div>

      {/* checkbox ooptions */}
      <div className="flex border-y-2 py-2">
        <ul className="w-full grid grid-cols-2">
          {filteredData.map((item: any, index: number) => {
            return (
              <li key={item.id} className="w-full">
                <CommonInputField
                  outerClass="py-2"
                  type="checkbox"
                  checked={checkedValues.includes(item.id)}
                  onChange={(e) =>
                    handleCheckboxChange(e.target.checked, item.id)
                  }
                  disabled={
                    checkedCount >= 5 && !checkedValues.includes(item.id)
                  }
                  label={item.name}
                />
              </li>
            );
          })}
        </ul>
      </div>

      {/* buttons */}
      <div className="inline-flex w-full">
        <CommonInputField
          outerClass="w-2/5 px-2 py-3 flex justify-center"
          innerClass="py-3 px-6 rounded-full border border-[#D40801] text-[#D40801] w-full text-xl font-bold cursor-pointer"
          type="button"
          value="Back"
          onClick={() => {
            dispatch(setRegistrationStep(step - 1));
          }}
        />
        <CommonInputField
          outerClass="w-3/5 px-2 py-3 flex justify-center"
          innerClass="py-3 px-6 rounded-full bg-[#D40801] w-full text-white text-xl font-bold cursor-pointer"
          type="button"
          value="Continue"
          onClick={() => {
            if (checkedValues.length) {
              const payload: any = {
                ...data,
                selectedTrades: checkedValues.map((id: any) => {
                  return {
                    id,
                    isStrongest: false,
                  };
                }),
              };
              dispatch(setRegisterFormData(payload));
              dispatch(setRegistrationStep(step + 1));
            } else {
              alert("Plase select atleast 1 trade");
            }
          }}
        />
      </div>
    </div>
  );
}
