import React, { useEffect, useState } from "react";
import { CommonInputField } from "../../../../common/components/CommonInputField";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import {
  registerFormData,
  setIsLoggedIn,
  setRegistrationStep,
  stepNo,
} from "../../../../app-redux/auth/authSlice";
import { IRegister } from "../../../../common/typings/register";
import { allTradesList } from "../../../../common/utils/app.content";
import DialogBox from "../../../../common/components/CommonDialog";
import { ax } from "../../../../common/api/apiClient";

export function Step6() {
  const step = useAppSelector(stepNo);
  const dispatch = useAppDispatch();
  const data = useAppSelector(registerFormData);
  const [selectedStrongestTrade, setSelectedStrongestTrade] = useState("");
  const [checkedValues, setCheckedValues] = useState<any>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");

  useEffect(() => {
    const vals: any = [];
    data.selectedTrades.forEach((item: any) => {
      vals.push(allTradesList.find((trade: any) => trade.id === item.id));
    });
    setCheckedValues(vals);
  }, [data]);

  const handleCloseDialog = () => {
    setShowDialog(false); // Hide the dialog box
  };

  const saveForm = async () => {
    if (selectedStrongestTrade == "") {
      alert("Please select your strongest trade first");
    } else {
      const payload: IRegister = {
        ...data,
        selectedTrades: data.selectedTrades.map((item: any) => {
          return item.id === selectedStrongestTrade
            ? { ...item, isStrongest: true }
            : { ...item };
        }),
      };

      try {
        const res: any = await ax.post(`/users/register`, payload);
        if (res && res.data.statusCode == 201) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("userType", "0");
          localStorage.setItem("userId", res.data.user.id);
          dispatch(
            setIsLoggedIn({
              isLoggedIn: true,
              isEngineer: true,
            })
          );
          dispatch(setRegistrationStep(step + 1));
        }
      } catch (error: any) {
        setShowDialog(true);
        setHeader(error.response.data.error.title);
        setMessage(error.response.data.error.detail);
      }
    }
  };

  return (
    <>
      <DialogBox
        isOpen={showDialog}
        header={header}
        message={message}
        onClose={handleCloseDialog}
      />
      <div>
        <p className="text-3xl font-bold mb-4">Select your strongest trade</p>

        {checkedValues.map((item: any, index: number) => {
          return (
            <div
              key={item.id}
              className={
                selectedStrongestTrade == item.id
                  ? "w-full text-center p-3 my-2 rounded-full cursor-pointer bg-[#4a90e21a] border-[#0077C0] border-2"
                  : "w-full text-center p-3 my-2 rounded-full cursor-pointer bg-[#F7F7F7] "
              }
              onClick={(e) => {
                setSelectedStrongestTrade(item.id);
              }}
            >
              {item.name}
            </div>
          );
        })}

        {/* buttons */}
        <div className="inline-flex w-full">
          <CommonInputField
            outerClass="w-2/5 px-2 py-3 flex justify-center"
            innerClass="py-3 px-6 rounded-full border border-[#D40801] text-[#D40801] w-full text-xl font-bold cursor-pointer"
            type="button"
            value="Back"
            onClick={() => {
              dispatch(setRegistrationStep(step - 1));
            }}
          />
          <CommonInputField
            outerClass="w-3/5 px-2 py-3 flex justify-center"
            innerClass="py-3 px-6 rounded-full bg-[#D40801] w-full text-white text-xl font-bold cursor-pointer"
            type="button"
            value="Save"
            onClick={saveForm}
          />
        </div>
      </div>
    </>
  );
}
