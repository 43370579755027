import React from "react";

interface Dialog {
  header: string;
  message: any;
  isOpen: boolean;
  onClose: any
}
const DialogBox = ({ header, message, isOpen, onClose }: Dialog) => {
  return (
    <>
      {isOpen ? (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="w-full md:w-1/2 lg:w-1/3 bg-white p-6 rounded shadow text-center border">
            <h2 className="text-lg font-bold mb-4">{header}</h2>
            <p>{message}</p>
            <button
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
              onClick={onClose}
            >
              {header == "Success" ? "Ok" : "Close"}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DialogBox;
