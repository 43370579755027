import { IRoute } from "../../../common/typings/routes";
import { AccountSettingsPage } from "./pages/AccountSettings";

export const AccountSettingsRoute: IRoute = {
  title: "Change Account Settings",
  path: "/change-account-settings",
  component: AccountSettingsPage,
  isAuthGuard: true,
  isNoAuthGuard: false
};
