import { faL } from "@fortawesome/free-solid-svg-icons";
import { IRoute } from "../../common/typings/routes";
import { EngineersList } from "./pages/EngineersList";
import { EngineersMain } from "./pages/EngineersMain";

export const EngineersRoute: IRoute = {
  title: "Tradepersons",
  path: "/engineers",
  component: EngineersMain,
  subRoutes: [
    { title: "Tradepersons Listing", component: EngineersList, path: "/listing", isAuthGuard: false, isNoAuthGuard: false },
  ],
  isAuthGuard: false,
  isNoAuthGuard: false
};
