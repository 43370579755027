import React from "react";
import { CommonInputField } from "../../../../common/components/CommonInputField";
import {
  registerFormData,
  setRegisterFormData,
  setRegistrationStep,
  stepNo,
} from "../../../../app-redux/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import { useFormik } from "formik";
import { object, string } from "yup";

export function Step4() {
  const step = useAppSelector(stepNo);
  const dispatch = useAppDispatch();
  const data = useAppSelector(registerFormData);
  const validationSchema = object({
    intro: string().required("This field is required"),
  });

  const formik = useFormik<any>({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      intro: data.intro || "",
    },
    onSubmit: async (values: any) => {
      const payload: any = {
        ...data,
        ...values,
      };
      dispatch(setRegisterFormData(payload));
      dispatch(setRegistrationStep(step + 1));
    },
  });

  return (
    <div>
      <p className="text-3xl font-bold mb-4">Introduce yourself to customers</p>

      <div className="p-2">
        Introduce your business by describing the work you normally undertake
        and what makes you stand out from other tradespeople. You can always
        edit this later.
      </div>

      <textarea
        name="intro"
        cols={30}
        rows={4}
        className={
          formik.touched.intro && Boolean(formik.errors.intro)
            ? "w-full m-2 p-2 border border-red-600 border-solid rounded-sm"
            : "w-full m-2 p-2 border border-gray-300 border-solid rounded-sm"
        }
        value={formik.values.intro}
        onChange={(event: any) => {
          formik.setFieldValue("intro", event.target.value);
        }}
      ></textarea>
      {formik.touched.intro && Boolean(formik.errors.intro) ? (
        <div className="text-red-600 mt-1 ml-6">This field is required</div>
      ) : (
        <></>
      )}

      {/* buttons */}
      <div className="inline-flex w-full">
        <CommonInputField
          outerClass="w-2/5 px-2 py-3 flex justify-center"
          innerClass="py-3 px-6 rounded-full border border-[#D40801] text-[#D40801] w-full text-xl font-bold cursor-pointer"
          type="button"
          value="Back"
          onClick={() => {
            dispatch(setRegistrationStep(step - 1));
          }}
        />
        <CommonInputField
          outerClass="w-3/5 px-2 py-3 flex justify-center"
          innerClass="py-3 px-6 rounded-full bg-[#D40801] w-full text-white text-xl font-bold cursor-pointer"
          type="button"
          value="Continue"
          onClick={() => {
            formik.submitForm();
          }}
        />
      </div>
    </div>
  );
}
