import axios from "axios";

let store: any;
export const injectStore = (_store: any) => {
  store = _store;
};

export const ax = axios.create();

ax.interceptors.request.use(async (config) => {
  const idToken = localStorage.getItem("token") || null;
  config.baseURL = process.env.REACT_APP_API_URL;
  config.headers.Authorization = `Bearer ${idToken}`;
  return config;
});
