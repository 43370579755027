import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  outerClass?: string;
  innerClass?: string;
  style?: any;
  type: string;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  checked?: boolean;
  onChange?: (v: any) => void;
  onClick?: (v: any) => void;
  ref?: any;
  key?: any;
  disabled?: boolean;
  error?: boolean;
  helperText?: any;
  handleTogglePassword?: (v: any) => void;
  showPassword?: boolean;
  isPasswordField?: boolean;
}

export const CommonInputField = ({
  outerClass,
  innerClass,
  style,
  type,
  name,
  label,
  placeholder,
  value,
  checked,
  onChange,
  onClick,
  ref,
  key,
  disabled,
  error = false,
  helperText = "",
  handleTogglePassword,
  showPassword,
  isPasswordField = false,
}: Props) => {
  return (
    <>
      {outerClass ? (
        <>
          <div className={outerClass}>
            <input
              className={
                error ? `${innerClass} border border-red-600` : innerClass
              }
              style={style}
              type={type}
              name={name}
              placeholder={placeholder}
              value={value}
              checked={checked}
              onChange={(e) => onChange && onChange(e)}
              onClick={(e) => onClick && onClick(e)}
              ref={ref}
              key={key}
              disabled={disabled}
            />
            {isPasswordField ? (
              <button
                className="pr-5 relative top-[-32px] float-right"
                onClick={(e) => handleTogglePassword && handleTogglePassword(e)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            ) : (
              <></>
            )}
            {type === "checkbox" ? (
              <label htmlFor={name} className="ml-2">
                {label}
              </label>
            ) : (
              <></>
            )}
            {error && type != "checkbox" ? (
              <div className="text-red-600 mt-1 ml-6">{helperText}</div>
            ) : (
              <></>
            )}
          </div>
          {error && type === "checkbox" ? (
            <div className="text-red-600 mt-1 ml-6">{helperText}</div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div>
          {/* ! */}
          <input
            className={
              error ? `${innerClass} border border-red-600` : innerClass
            }
            style={style}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            checked={checked}
            onChange={(e) => onChange && onChange(e)}
            onClick={(e) => onClick && onClick(e)}
            ref={ref}
            key={key}
            disabled={disabled}
          />
          {isPasswordField ? (
            <button
              className="w-full text-right pr-5 relative top-[-32px]"
              onClick={(e) => handleTogglePassword && handleTogglePassword(e)}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          ) : (
            <></>
          )}
          {type === "checkbox" ? (
            <label htmlFor={name} className="ml-2">
              {label}
            </label>
          ) : (
            <></>
          )}
          {error ? (
            <div className="text-red-600 mt-1 ml-6">{helperText}</div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};
