import { IRoute } from "../../../common/typings/routes";
import { Login } from "./pages/Login";

export const LoginRoute: IRoute = {
  title: "Login",
  path: "/login",
  component: Login,
  isAuthGuard: false,
  isNoAuthGuard: true
};
