import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { weekDays } from "../../../common/utils/app.content";
import { CommonInputField } from "../../../common/components/CommonInputField";
import { CommonSelect } from "../../../common/components/CommonSelect";
import CalenderIcon from "../../../assets/icons/icon-calendar.png";
import CloudIcon from "../../../assets/icons/icon-cloud.png";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { object, string } from "yup";
import DialogBox from "../../../common/components/CommonDialog";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import {
  postJobFormData,
  setPostJobFormData,
} from "../../../app-redux/main/mainSlice";
import { ax } from "../../../common/api/apiClient";

export function PostJob() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  const data = useAppSelector(postJobFormData);

  const [servicesList, setServicesList] = useState([]);
  const validationSchema = object({
    name: string().required("This field is required"),
    phoneNo: string()
      .required("This field is required")
      .matches(/^[+0-9- ]*$/, "Please enter a valid phone number"),
    email: string()
      .email("Please enter a valid email")
      .required("This field is required"),
    address: string().required("This field is required"),
    address2: string().required("This field is required"),
    city: string().required("This field is required"),
    state: string().required("This field is required"),
    postCode: string().required("This field is required"),
    day: string().required("This field is required"),
    date: string().required("This field is required"),
    time: string().required("This field is required"),
    serviceType: string().required("This field is required"),
    description: string().required("This field is required"),
  });

  const formik = useFormik<any>({
    validationSchema: validationSchema,
    initialValues: {
      name: data.name || "", //1
      phoneNo: data.phoneNo || "", //1
      email: data.email || "", //1
      address: data.address || "", //1
      address2: data.address2 || "", //2
      city: data.city || "", //1
      state: data.state || "", //1
      postCode: data.postCode || "", //1
      day: data.day || "", //1
      date: data.date ? new Date(data.date) : "", //1
      time: data.time || "", //1
      serviceType: data.serviceType || "", //2
      description: data.description || "", //2, 4-5
      appointmentDate: data.appointmentDate || "", //1
      appointmentTime: data.appointmentTime || "", //1
      file: data.file || "", //1
    },
    onSubmit: async (values: any) => {
      if (!localStorage.getItem("token")) {
        dispatch(
          setPostJobFormData({
            ...values,
            date: moment(values.date).format("YYYY-MM-DD"),
          })
        );
        history.push("/login");
        return;
      }

      const payload = {
        ...values,
        date: moment(values.date).format("YYYY-MM-DD"),
        postedBy: localStorage.getItem("userId"),
      };
      try {
        const res: any = await ax.post(`/jobs/post`, payload);
        if (res && res.data.statusCode == 200) {
          setShowDialog(true);
          setHeader("Success");
          setMessage(res.data.message);
          resetForm();
        }
      } catch (error: any) {
        setShowDialog(true);
        setHeader("Error");
        setMessage(error.response.data.error);
      }
    },
  });

  const resetForm = () => {
    formik.resetForm();
  }

  useEffect(() => {
    getAllServices();
  }, []);

  const getAllServices = async () => {
    try {
      const res: any = await ax.get(`/getAllServices`);
      if (res && res.data.statusCode == 200) {
        setServicesList(res.data.value);
      }
    } catch (error: any) {
      throw new Error(error.response.data.error);
    }
  };

  const fileInputRef: any = useRef(null);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleCloseDialog = () => {
    setShowDialog(false); // Hide the dialog box
  };

  return (
    <>
      <DialogBox
        isOpen={showDialog}
        header={header}
        message={message}
        onClose={handleCloseDialog}
      />
      <div className="mx-auto my-16 w-full md:w-5/6 xl:w-2/3 px-3 md:px-0">
        {/* Form */}
        <div className="bg-white p-6 shadow-lg">
          <p className="text-4xl font-bold text-center">Post a Job</p>
          <div className="flex flex-wrap">
            <CommonInputField
              outerClass="w-full md:w-1/2 px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="name"
              placeholder="Name*"
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onChange={(event: any) => {
                formik.setFieldValue("name", event.target.value);
              }}
            />
            <CommonInputField
              outerClass="w-full md:w-1/2 px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="phoneNo"
              placeholder="Phone Number*"
              value={formik.values.phoneNo}
              error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
              helperText={formik.touched.phoneNo && formik.errors.phoneNo}
              onChange={(event: any) => {
                formik.setFieldValue("phoneNo", event.target.value);
              }}
            />
            <CommonInputField
              outerClass="w-full md:w-1/2 px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="email"
              name="email"
              placeholder="Email*"
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onChange={(event: any) => {
                formik.setFieldValue("email", event.target.value);
              }}
            />
            <CommonInputField
              outerClass="w-full md:w-1/2 px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="address"
              placeholder="Address*"
              value={formik.values.address}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              onChange={(event: any) => {
                formik.setFieldValue("address", event.target.value);
              }}
            />
            <CommonInputField
              outerClass="w-full px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="address2"
              placeholder="Street Address Line 2"
              value={formik.values.address2}
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={formik.touched.address2 && formik.errors.address2}
              onChange={(event: any) => {
                formik.setFieldValue("address2", event.target.value);
              }}
            />
            <CommonInputField
              outerClass="w-full md:w-1/2 px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="city"
              placeholder="City*"
              value={formik.values.city}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              onChange={(event: any) => {
                formik.setFieldValue("city", event.target.value);
              }}
            />
            <CommonInputField
              outerClass="w-full md:w-1/2 px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="state"
              placeholder="State/Province*"
              value={formik.values.state}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              onChange={(event: any) => {
                formik.setFieldValue("state", event.target.value);
              }}
            />
            <CommonInputField
              outerClass="w-full md:w-1/2 px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="postCode"
              placeholder="Postal / Zip Code*"
              value={formik.values.postCode}
              error={formik.touched.postCode && Boolean(formik.errors.postCode)}
              helperText={formik.touched.postCode && formik.errors.postCode}
              onChange={(event: any) => {
                formik.setFieldValue("postCode", event.target.value);
              }}
            />
            <CommonSelect
              outerClass="w-full md:w-1/2 px-2 py-3"
              innerClass={
                formik.touched.day && Boolean(formik.errors.day)
                  ? `pt-4 pb-3 px-6 rounded-full bg-[#F7F7F7] w-full border border-red-600  ${
                      formik.values.day !== "" ? "text-black" : "text-gray-400"
                    }`
                  : `pt-4 pb-3 px-6 rounded-full bg-[#F7F7F7] w-full  ${
                      formik.values.day !== "" ? "text-black" : "text-gray-400"
                    }`
              }
              name="day"
              value={formik.values.day}
              error={formik.touched.day && Boolean(formik.errors.day)}
              helperText={formik.touched.day && formik.errors.day}
              selectOptions={weekDays}
              option1="What day works best for you?*"
              onChange={(event: any) => {
                formik.setFieldValue("day", event.target.value);
              }}
            />
            <div className="relative w-full md:w-1/2 px-2 py-3">
              <DatePicker
                className={
                  formik.touched.date && Boolean(formik.errors.date)
                    ? "py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full border border-red-600"
                    : "py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
                }
                selected={formik.values.date}
                placeholderText="Select Particular Day"
                onChange={(date: any) => formik.setFieldValue("date", date)}
                minDate={new Date()}
              />
              <img
                src={CalenderIcon}
                alt=""
                className="absolute top-6 right-7"
              />
              {formik.touched.date && Boolean(formik.errors.date) ? (
                <div className="text-red-600 mt-1 ml-6">
                  This field is required
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="flex w-full md:w-1/2">
              <label
                htmlFor="time"
                className="w-3/5 md:w-1/2 px-8 py-4 md:py-2 xl:py-6 text-gray-400"
              >
                Select Particular Time of Day
              </label>
              <CommonInputField
                outerClass="w-2/5 md:w-1/2 px-2 py-3"
                innerClass={`py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full ${
                  formik.values.time !== "" ? "text-black" : "text-gray-400"
                }`}
                type="time"
                name="time"
                placeholder="Select Particular Day"
                value={formik.values.time}
                error={formik.touched.time && Boolean(formik.errors.time)}
                helperText={formik.touched.time && formik.errors.time}
                onChange={(event: any) => {
                  formik.setFieldValue("time", event.target.value);
                }}
              />
            </div>
            <CommonSelect
              outerClass="w-full px-2 py-3"
              innerClass={
                formik.touched.serviceType && Boolean(formik.errors.serviceType)
                  ? `pt-4 pb-3 px-6 rounded-full bg-[#F7F7F7] w-full border border-red-600  ${
                      formik.values.day !== "" ? "text-black" : "text-gray-400"
                    }`
                  : `pt-4 pb-3 px-6 rounded-full bg-[#F7F7F7] w-full  ${
                      formik.values.day !== "" ? "text-black" : "text-gray-400"
                    }`
              }
              name="serviceType"
              value={formik.values.serviceType}
              error={
                formik.touched.serviceType && Boolean(formik.errors.serviceType)
              }
              helperText={
                formik.touched.serviceType && formik.errors.serviceType
              }
              selectOptions={servicesList}
              option1="What Services are you interested in?"
              onChange={(event: any) => {
                formik.setFieldValue("serviceType", event.target.value);
              }}
            />
            <div className="w-full px-2 py-3">
              <textarea
                className={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                    ? "pt-4 pb-3 px-6 rounded-xl bg-[#F7F7F7] w-full placeholder-gray-400 border border-red-600"
                    : "pt-4 pb-3 px-6 rounded-xl bg-[#F7F7F7] w-full placeholder-gray-400"
                }
                placeholder="Please give a brief description of the work you need performed.*"
                name="description"
                id="description"
                cols={70}
                rows={6}
                value={formik.values.description}
                onChange={(event: any) => {
                  formik.setFieldValue("description", event.target.value);
                }}
              ></textarea>
              {formik.touched.description &&
              Boolean(formik.errors.description) ? (
                <div className="text-red-600 mt-1 ml-6">
                  This field is required
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* Appointment Date */}
            {/* <div className="relative w-full md:w-1/2 px-2 py-3">
            <DatePicker
              className="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              selected={formik.values.appointmentDate}
              placeholderText="Appointment"
              onChange={(date: any) =>
                formik.setFieldValue("appointmentDate", date)
              }
            />
            <img src={CalenderIcon} alt="" className="absolute top-6 right-7" />
          </div> */}
            {/* Appointment Time */}
            {/* <div className="flex w-full md:w-1/2">
            <label htmlFor="time" className="w-1/4 px-8 py-6 text-gray-400">
              Time
            </label>
            <CommonInputField
              outerClass="w-3/4 px-2 py-3"
              innerClass={`py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full ${
                formik.values.time !== "" ? "text-black" : "text-gray-400"
              }`}
              type="time"
              name="appointmentTime"
              value={formik.values.appointmentTime}
              onChange={(event: any) => {
                formik.setFieldValue("appointmentTime", event.target.value);
              }}
            />
          </div> */}
            {/* File */}
            {/* <div className="flex flex-wrap lg:flex-nowrap w-full">
            <label htmlFor="date" className="w-full lg:w-1/5 px-8 py-6 text-xl font-bold">
              File Upload
            </label>
            <div className="relative w-full lg:w-4/5 px-2 py-3" style={{height: "200px"}}>
              <input
                type="file"
                ref={fileInputRef}
                className={"opacity-0 absolute inset-0 w-full h-full"}
                name="file"
                placeholder="File"
                value={formik.values.file}
                onChange={(event: any) => {
                  formik.setFieldValue("file", event.target.value);
                }}
              />
              <div
                className="absolute inset-0 cursor-pointer py-3 px-6 rounded-3xl text-black bg-[#F7F7F7] flex justify-center h-full items-center mx-2 lg:mx-0"
                onClick={handleFileInputClick}
              >
                {formik.values.file ? (
                  <div>{formik.values.file}</div>
                ) : (
                  <div className="flex flex-wrap justify-center text-center">
                    <img src={CloudIcon} alt="" />
                    <p className="w-full">Browse File</p>
                    <p className="w-full">Drag and Drop files here</p>
                  </div>
                )}
              </div>
            </div>
          </div> */}
            <CommonInputField
              outerClass="w-full px-2 py-3 flex justify-center"
              innerClass="py-3 px-6 rounded-full bg-[#D40801] w-2/5 text-white text-xl font-bold cursor-pointer"
              type="button"
              value="Submit"
              onClick={() => {
                formik.submitForm();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
