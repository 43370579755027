import { IRoute } from "../../common/typings/routes";
import { JobsList } from "./pages/JobsList";
import { JobsMain } from "./pages/JobsMain";
import { ViewJob } from "./pages/ViewJob";

export const JobRoute: IRoute = {
  title: "Jobs",
  path: "/jobs",
  component: JobsMain,
  subRoutes: [
    { title: "Jobs Listing", component: JobsList, path: "/listing", isAuthGuard: true, isNoAuthGuard: false },
    // { title: "View Job", component: ViewJob, path: "/view", pathParam: "/:id" },
  ],
  isAuthGuard: true,
  isNoAuthGuard: false
};
