import React from "react";

interface Props {
  outerClass?: string;
  innerClass: string;
  name: string;
  value: string;
  selectOptions: any[];
  option1?: string;
  onChange?: (v: any) => void;
  error?: any;
  helperText?: any;
}

export const CommonSelect = ({
  outerClass,
  innerClass,
  name,
  value,
  selectOptions,
  option1,
  onChange,
  error,
  helperText,
}: Props) => {
  return (
    <div className={outerClass}>
      <select
        className={innerClass}
        name={name}
        value={value}
        onChange={(e) => onChange && onChange(e)}
      >
        <option value="" disabled>
          {option1 ? option1 : "Select"}
        </option>
        {selectOptions.map((item, index) => {
          return (
            <option key={index} value={item.id} className="text-black">
              {item.name}
            </option>
          );
        })}
      </select>
      {error ? (
        <div className="text-red-600 mt-1 ml-6">{helperText}</div>
      ) : (
        <></>
      )}
    </div>
  );
};
