import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/logo.png";
import UserIcon from "../../assets/icons/icon_user.png";
import {
  navMenuItemsEngineers,
  navMenuItemsHomeowners,
  navMenuItemsHomeownersLoggedIn,
} from "../utils/app.content";
import { CommonInputField } from "../components/CommonInputField";
import { useAppSelector } from "../../app-redux/hooks";
import { isUserEngineer, isUserLoggedIn } from "../../app-redux/auth/authSlice";

export function Header() {
  const history = useHistory();
  const menuItemsHomeowners = navMenuItemsHomeowners;
  const menuItemsHomeownersLoggedIn = navMenuItemsHomeownersLoggedIn;
  const menuItemsEngineers = navMenuItemsEngineers;
  const isLoggedIn = useAppSelector(isUserLoggedIn);
  const isEngineer = useAppSelector(isUserEngineer);

  const handleClick = (path: string) => {
    history.push(path);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="bg-black py-4">
        <nav>
          <div className="mx-auto px-4 sm:px-6 lg:px-8 w-full">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between lg:block">
              <div className="flex items-center justify-between h-16">
                {/* Logo */}
                <div className="flex-shrink-0">
                  <img src={Logo} alt="Logo" className="w-[212px]" />
                </div>

                {/* Navbar */}
                <div className="hidden lg:flex items-center space-x-4">
                  {isEngineer || localStorage.getItem("userType") == "0"
                    ? menuItemsEngineers.map((item, index) => {
                        return (
                          <a
                            key={index}
                            href={item.route}
                            className={item.classes}
                          >
                            {item.name}
                          </a>
                        );
                      })
                    : isLoggedIn || localStorage.getItem("token")
                    ? menuItemsHomeownersLoggedIn.map((item, index) => {
                        return (
                          <a
                            key={index}
                            href={item.route}
                            className={item.classes}
                          >
                            {item.name}
                          </a>
                        );
                      })
                    : menuItemsHomeowners.map((item, index) => {
                        return (
                          <a
                            key={index}
                            href={item.route}
                            className={
                              item.route == "/engineer/register"
                                ? `bg-[#CD171F] rounded-3xl ${item.classes}`
                                : item.classes
                            }
                          >
                            {item.name}
                          </a>
                        );
                      })}
                  {isLoggedIn || localStorage.getItem("token") ? (
                    <CommonInputField
                      innerClass="py-2 px-6 rounded-full bg-[#D40801] text-white cursor-pointer"
                      type="button"
                      value="Logout"
                      onClick={() => {
                        localStorage.clear();
                        history.push("/home");
                        location.reload();
                      }}
                    />
                  ) : (
                    <a href="/login" className="px-3 py-2">
                      <img
                        src={UserIcon}
                        alt=""
                        className="bg-[#0077C0] px-3 py-2 rounded-full"
                      />
                    </a>
                  )}
                </div>
              </div>
              <div className="-mr-2 flex lg:hidden">
                <button
                  onClick={toggleMenu}
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {isOpen && (
            <div className="lg:hidden">
              <div className="px-2 pt-2 pb-3 sm:px-3">
                {isEngineer || localStorage.getItem("userType") == "0"
                  ? menuItemsEngineers.map((item, index) => {
                      return (
                        <a
                          key={index}
                          href={item.route}
                          className={item.classes}
                        >
                          {item.name}
                        </a>
                      );
                    })
                  : isLoggedIn || localStorage.getItem("token") ? menuItemsHomeownersLoggedIn.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href={item.route}
                        className={`block ${item.classes}`}
                      >
                        {item.name}
                      </a>
                    );
                  }) : menuItemsHomeowners.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href={item.route}
                        className={`block ${item.classes}`}
                      >
                        {item.name}
                      </a>
                    );
                  })}
                {isLoggedIn || localStorage.getItem("token") ? (
                  <CommonInputField
                    outerClass="w-full px-2 py-3 flex justify-center"
                    innerClass="py-3 px-6 rounded-full bg-[#D40801] w-full md:w-2/3 xl:w-2/5 text-white text-xl font-bold cursor-pointer"
                    type="button"
                    value="Logout"
                    onClick={() => {
                      localStorage.clear();
                      history.push("/home");
                    }}
                  />
                ) : (
                  <a href="/login" className="block px-3 py-2">
                    <img
                      src={UserIcon}
                      alt=""
                      className="bg-[#0077C0] px-3 py-2 rounded-full"
                    />
                  </a>
                )}
              </div>
            </div>
          )}
        </nav>
      </header>
    </>
  );
}
