import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface MainState {
  popularSearches: string;
  jobsList: any[];
  paginationJobs: any;
  engineersList: any[];
  paginationEngineers: any;
  testimonials: any[];
  showViewSection: boolean;
  breadcrumbsItems: any;
  postJobFormData: any;
  loginPageRedirectedFrom: string;
  homeSearchString: string;
}

const initialState: MainState = {
  popularSearches: "",
  jobsList: [],
  paginationJobs: {
    pageCount: 1,
    noOfRecords: 0,
    offset: 0,
    itemsPerPage: 5,
  },
  engineersList: [],
  paginationEngineers: {
    pageCount: 1,
    noOfRecords: 0,
    offset: 0,
    itemsPerPage: 5,
  },
  testimonials: [],
  showViewSection: false,
  breadcrumbsItems: {},
  postJobFormData: {},
  loginPageRedirectedFrom: "",
  homeSearchString: "",
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setJobsList: (state: any, value) => {
      state.jobsList = value.payload;
    },
    setJobsPaginationControls: (state: any, value) => {
      state.paginationJobs = value.payload;
    },
    setEngineersList: (state: any, value) => {
      state.engineersList = value.payload;
    },
    setEngineersPaginationControls: (state: any, value) => {
      state.paginationEngineers = value.payload;
    },
    setPopularSearches: (state: any, value) => {
      state.popularSearches = value.payload;
    },
    setTestimonials: (state: any, value) => {
      state.testimonials = value.payload;
    },
    setShowViewSection: (state: any, value) => {
      state.showViewSection = value.payload;
    },
    setBreadcrumbsItems: (state: any, value) => {
      state.breadcrumbsItems = value.payload;
    },
    setPostJobFormData: (state: any, value) => {
      state.loginPageRedirectedFrom = "post-job";
      state.postJobFormData = value.payload;
    },
    sethomeSearchString: (state: any, value) => {
      state.homeSearchString = value.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setPopularSearches,
  setJobsList,
  setEngineersList,
  setTestimonials,
  setJobsPaginationControls,
  setEngineersPaginationControls,
  setShowViewSection,
  setBreadcrumbsItems,
  setPostJobFormData,
  sethomeSearchString,
} = mainSlice.actions;

export const allJobsList = (state: RootState) => state.main.jobsList;
export const allEngineersList = (state: RootState) => state.main.engineersList;
export const paginationControlsJobs = (state: RootState) =>
  state.main.paginationJobs;
export const paginationControlsEngineers = (state: RootState) =>
  state.main.paginationEngineers;
export const allPopularSearches = (state: RootState) =>
  state.main.popularSearches;
export const allTestimonials = (state: RootState) => state.main.testimonials;
export const viewSection = (state: RootState) => state.main.showViewSection;
export const breadcrumbs = (state: RootState) => state.main.breadcrumbsItems;
export const postJobFormData = (state: RootState) => state.main.postJobFormData;
export const loginPageRedirectedFrom = (state: RootState) => state.main.loginPageRedirectedFrom;
export const homeSearchString = (state: RootState) => state.main.homeSearchString;
