import React from "react";
import { Banner } from "./Banner";
import { Post } from "./Post";
import { Featured } from "./Featured";
import { Testimonials } from "./Testimonials";
import { useHistory } from "react-router-dom";
import { CommonInputField } from "../../../common/components/CommonInputField";
import { useAppSelector } from "../../../app-redux/hooks";
import { isUserLoggedIn } from "../../../app-redux/auth/authSlice";

export function HomePage() {
  const history = useHistory();
  const isLoggedIn = useAppSelector(isUserLoggedIn);

  return (
    <div>
      <Banner />
      {!localStorage.getItem("token") && <Post />}
      <Featured />
      <Testimonials />
      {/* Register Section */}
      {!isLoggedIn && !localStorage.getItem("token") ? (
        <div className="flex flex-col items-center mt-16">
          <p className="text-3xl md:text-5xl font-bold text-center">
            Register as an tradesperson & <br /> reach more
            homeowners now!
          </p>
          <CommonInputField
            innerClass="bg-[#0077C0] my-8 py-2 px-10 rounded-full cursor-pointer text-white"
            type="button"
            value="Register My Trade"
            onClick={() => {
              history.push("/engineer/register");
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
