import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import { allEngineersList } from "../../../app-redux/main/mainSlice";
import LocationIcon from "../../../assets/icons/icon-location-red.png";
import { CommonInputField } from "../../../common/components/CommonInputField";
import DialogBox from "../../../common/components/CommonDialog";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import iconSort from "../../../assets/icons/icon-sort.png";
import { ax } from "../../../common/api/apiClient";

export function FavoriteEngineersList() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [favEngineersList, setFavEngineersList] = useState<any>([]);

  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState<any>(null);
  const [pagination, setPagination] = useState({
    pageCount: 1,
    noOfRecords: 0,
    offset: 0,
    itemsPerPage: 5,
  });
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState("id");

  useEffect(() => {
    getEngineers();
  }, [offset, sortBy]);

  const getEngineers = async () => {
    const id = localStorage.getItem("userId");
    if (!id) {
      setShowDialog(true);
      setHeader("Error");
      setMessage("Please Login First");
      return;
    }

    const payload = {
      id: id || "",
      limit: 5,
      offset: offset,
    };
    try {
      const res: any = await ax.post(`/engineers/allFavoriteUsers`, payload);
      if (res && res.data.statusCode == 200) {
        setFavEngineersList(res.data.value || []);
        setPagination(res.data.pagination);
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const removeFromFavorites = async (favId: number) => {
    if (!localStorage.getItem("token")) {
      setShowDialog(true);
      setHeader("Error");
      setMessage("Please login first");
      // history.push("/login");
      return;
    }

    try {
      const res: any = await ax.delete(
        `/engineers/removeFromFavorites/${localStorage.getItem(
          "userId"
        )}/${favId}`
      );
      if (res && res.data.statusCode == 200) {
        setShowDialog(true);
        setHeader("Success");
        setMessage("Successfully Removed Tradesperson from Favorites");
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const getEngineer = async (id: number) => {
    try {
      const res: any = await ax.get(`/engineers/view/${id}`);
      if (res && res.data.statusCode == 200) {
        setDetails(res.data.value[0]);
        setIsOpen(true);
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false); // Hide the dialog box
    if (message === "Please Login First") history.push("/login");
    getEngineers();
  };

  const handleSort = (sortBy: string) => {
    setSortBy(sortBy);
  };

  const handlePageClick = (event: any) => {
    const newOffset =
      (event.selected * pagination.itemsPerPage) % pagination.noOfRecords;
    setOffset(newOffset);
  };

  return (
    <>
      <DialogBox
        isOpen={showDialog}
        header={header}
        message={message}
        onClose={handleCloseDialog}
      />
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow w-2/3 lg:w-1/2">
            <h2 className="text-lg font-bold mb-4 text-center">
              Tradesperson Details
            </h2>
            <hr />
            <div className="max-h-80 overflow-y-auto">
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Name: </p>
                  <p className="w-1/2 px-1 break-all">
                    {details.firstName} {details.lastName}
                  </p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Phone: </p>
                  <p className="w-1/2 px-1 break-all">{details.phoneNo}</p>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Email: </p>
                  <p className="w-1/2 px-1 break-all">{details.email}</p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Trade Type: </p>
                  <p className="w-1/2 px-1 break-all">
                    {details.tradingType == "1"
                      ? "Self-employed / Sole Trader"
                      : details.tradingType == "2"
                      ? "Limited Company"
                      : details.tradingType == "3"
                      ? "Ordinary Partnership"
                      : details.tradingType == "4"
                      ? "Limited Partnership"
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Trading Name: </p>
                  <p className="w-1/2 px-1 break-all">{details.tradingName}</p>
                </div>
                {details.registeredCompanyName && (
                  <div className="w-full md:w-1/2 flex m-3">
                    <p className="font-bold w-1/2 px-1">
                      Registered Company Name:{" "}
                    </p>
                    <p className="w-1/2 px-1 break-all">
                      {details.registeredCompanyName}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-row">
                {details.companyRegistrationNumber && (
                  <div className="w-full md:w-1/2 flex m-3">
                    <p className="font-bold w-1/2 px-1">
                      Company Registration Number:{" "}
                    </p>
                    <p className="w-1/2 px-1 break-all">
                      {details.companyRegistrationNumber}
                    </p>
                  </div>
                )}
                {details.partnersFullNames && (
                  <div className="w-full md:w-1/2 flex m-3">
                    <p className="font-bold w-1/2 px-1">
                      Full Names of Partners:{" "}
                    </p>
                    <p className="w-1/2 px-1 break-all">
                      {details.partnersFullNames}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Work Address: </p>
                  <p className="w-1/2 px-1 break-all">{details.workAddress}</p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Work Address 2: </p>
                  <p className="w-1/2 px-1 break-all">{details.workAddress2}</p>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Town: </p>
                  <p className="w-1/2 px-1 break-all">{details.town}</p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Postal Code: </p>
                  <p className="w-1/2 px-1 break-all">{details.postcode}</p>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">
                    Distance willing to travel:
                  </p>
                  <p className="w-1/2">{details.distance}</p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Introduction: </p>
                  <p className="w-1/2 px-1 break-all">{details.intro}</p>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Selected Trades: </p>
                  <p className="w-1/2 px-1 break-all">
                    {details.selectedTrades}
                  </p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Strongest Trade: </p>
                  <p className="w-1/2 px-1 break-all">
                    {details.strongestTrade}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex justify-center">
              <button
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                onClick={closeDialog}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Pagination controls */}
      <div className="w-full lg:w-3/4 rounded-2xl md:rounded-full flex flex-wrap lg:flex-nowrap mx-auto my-12 py-3 px-6 border border-gray-400 border-solid">
        <div className="flex w-full xl:w-1/2">
          <div
            className="flex py-3 px-4 cursor-pointer w-1/2"
            onClick={() => {
              handleSort("id");
            }}
          >
            <img
              src={iconSort}
              alt=""
              className="py-1 pr-2 xl:px-3  mt-0 mb-auto"
            />
            <p>Sort by id</p>
            {/* <img src={iconSortArrow} alt="" className="px-1 py-2" /> */}
          </div>
          <p className="text-4xl text-gray-300">|</p>
          <div
            className="flex py-3 px-4 cursor-pointer w-1/2"
            onClick={() => {
              handleSort("firstName");
            }}
          >
            <img
              src={iconSort}
              alt=""
              className="py-1 pr-2 xl:px-3  mt-0 mb-auto"
            />
            <p>Sort by name</p>
            {/* <img src={iconSortArrow} alt="" className="px-1 py-2" /> */}
          </div>
          <p className="text-4xl text-gray-300">|</p>
        </div>
        <div className="flex py-3 pl-3 xl:px-10 justify-between w-full ">
          {" "}
          {/* md:w-3/5 xl:w-2/3 */}
          <p>
            Showing {pagination.offset + 1} -{" "}
            {pagination.offset + pagination.itemsPerPage >
            pagination.noOfRecords
              ? pagination.noOfRecords
              : pagination.offset + pagination.itemsPerPage}{" "}
            of {pagination.noOfRecords} results
          </p>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pagination.pageCount}
            previousLabel=""
            renderOnZeroPageCount={null}
            className="w-1/2 flex justify-evenly"
          />
        </div>
      </div>
      <div className="flex flex-wrap justify-center">
        {favEngineersList.map((item: any, index: number) => {
          return (
            <div
              key={item.id}
              className="bg-white m-4 p-6 rounded-2xl flex flex-wrap lg:flex-nowrap w-full lg:w-3/4"
            >
              <div className="w-full flex justify-center md:justify-between flex-wrap md:flex-nowrap items-center">
                <div className="p-8">
                  <p className="text-2xl font-bold">
                    {item.firstName} {item.lastName}
                  </p>
                  <p className="text-xl">{item.email}</p>
                  <p className="text-xl">{item.phoneNo}</p>
                </div>
                <div className="p-8">
                  <div className="text-xl flex">
                    <img src={LocationIcon} alt="" />{" "}
                    <p className="pl-2">{item.town}</p>
                  </div>
                  <CommonInputField
                    innerClass="bg-[#0077C0] mx-2 my-2 py-2 px-10 rounded-full cursor-pointer text-white"
                    type="button"
                    value="View Tradesperson"
                    onClick={() => {
                      getEngineer(item.id);
                    }}
                  />
                  <CommonInputField
                    innerClass="bg-[#CD171F] mx-2 my-2 py-2 px-10 rounded-full cursor-pointer text-white"
                    type="button"
                    value="Remove From Favorites"
                    onClick={() => {
                      removeFromFavorites(item.id);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
