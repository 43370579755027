import React from "react";
import { Redirect } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  redirectPath: string;
  path: string;
  key: number;
}

export function NoAuthGuard(props: Props) {
  return localStorage.getItem('token') ? <Redirect to={{ pathname: props.redirectPath }} /> : <>{props.children}</>;
}
