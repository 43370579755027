import React, { useEffect, useRef, useState } from "react";
import DialogBox from "../../common/components/CommonDialog";
import { useHistory } from "react-router-dom";
import { ax } from "../../common/api/apiClient";
import moment from "moment";
import { io, Socket } from 'socket.io-client';

interface Props {
  redirectedFrom: string;
}

export function Chat({ redirectedFrom }: Props) {
  const history = useHistory();
  const [messages, setMessages] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [header, setHeader] = useState("");
  const socketUrl =
    process.env.REACT_APP_SOCKET_URL || "wss://api.findengineers.co.uk";
  const [socket, setSocket] = useState<Socket | null>(null);
  const messagesContainerRef: any = useRef(null);
  const messageInputRef: any = useRef(null);

  useEffect(() => {
    const socketConst = io(socketUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 3,
      withCredentials: false,
      transports: ["websocket", "polling"],
    });
    setSocket(socketConst);

    return () => {
      socket ? socket.disconnect() : "";
    };
  }, []);

  useEffect(() => {
    if (socket) {
      // socket.on("connect", () => {
      //   console.log("Connected to the server");
      // });
      // socket.on("connect_error", (err: any) => {
      //   console.log(`connect_error due to ${err.message}`, err);
      // });
      // socket.on("reconnect_attempt", () => {
      //   console.log("Reconnecting socket!!");
      //   socket.io.opts.transports = ["polling", "websocket"];
      // });

      socket.on("chatMessage", (data: any) => {
        const message = {
          senderId: data.senderId,
          receiverId: data.receiverId,
          message: data.message,
          timestamp: data.timestamp,
        };
        
        setMessages([...messages, message]);
      });
    }
  }, [socket, messages]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }

    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  }, [messages]);

  const handleMessageSubmit = async () => {
    if (!selectedUser) {
      setHeader("Error");
      setDialogMessage("Please select a user first");
      setShowDialog(true);
      return;
    } else if (!message) {
      setHeader("Error");
      setDialogMessage("Please type something");
      setShowDialog(true);
      return;
    }

    const messageObj = {
      senderId: localStorage.getItem("userId"),
      receiverId: selectedUser.id,
      message: message,
      timestamp: moment().format("yyyyMMDDTHHmmss"),
    };
    socket ? socket.emit("chatMessage", messageObj) : "";

    try {
      const res: any = await ax.post(`/chat/saveMessage`, messageObj);
      if (res && res.data.statusCode == 200) {
        setMessage("");
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getAllUsers();
    } else {
      setHeader("Error");
      setDialogMessage("Please Login First");
      setShowDialog(true);
    }
  }, []);

  const getAllUsers = async () => {
    try {
      const res: any = await ax.get(
        `/chat/${
          redirectedFrom == "engineers" ? "getAllEngineers" : "getAllHomeowners"
        }`
      );
      if (res && res.data.statusCode == 200) {
        setUsers(res.data.value);
        setFilteredUsers(res.data.value);
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const getUserMessages = async (userId: any) => {
    try {
      const res: any = await ax.get(
        `/chat/getAllMessages/${localStorage.getItem("userId")}/${userId}`
      );
      if (res && res.data.statusCode == 200) {
        setMessages(res.data.value);
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false); // Hide the dialog box
    if (dialogMessage === "Please Login First") history.push("/login");
  };

  const findUsers = (value: string) => {
    setFilteredUsers(
      users.filter((item: any) =>
        item.firstName
          .concat(" " + item.lastName)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && message.trim() !== '') {
      handleMessageSubmit()
    }
  };

  return (
    <>
      <DialogBox
        isOpen={showDialog}
        header={header}
        message={dialogMessage}
        onClose={handleCloseDialog}
      />
      <div className="flex flex-wrap justify-center">
        <div className="w-full md:w-2/3 border border-gray-400 rounded-lg flex">
          <div className="w-full md:w-1/3 border border-r-gray-400">
            <div className="border border-b-gray-400 border-t-0 p-3 text-center">
              <span className="text-xl font-bold">
                {redirectedFrom == "engineers" ? "Tradepersons" : "Homeowners"}
              </span>
            </div>
            <div className="h-15 p-2">
              <input
                className="py-2 px-6 rounded-full text-black border bg-gray-200 w-full"
                type="text"
                placeholder={
                  redirectedFrom == "engineers"
                    ? "Search Tradepersons"
                    : "Search Homeowners"
                }
                onChange={(e) => findUsers(e.target.value)}
              />
            </div>
            <ul className="h-96 overflow-auto ">
              {filteredUsers.map((user: any) => (
                <li
                  key={user.id}
                  className="p-5 border border-b-gray-400 cursor-pointer hover:bg-gray-200"
                  onClick={() => {
                    setSelectedUser(user);
                    getUserMessages(user.id);
                  }}
                >
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-2/3">
            <div className="border border-b-gray-400 p-3 text-center">
              <span className={"text-xl font-bold"}>
                {selectedUser ? selectedUser.firstName : "No User"}{" "}
                {selectedUser ? selectedUser.lastName : "Selected"}
              </span>
            </div>
            <div className="h-96 overflow-auto"  ref={messagesContainerRef}>
              {messages.map((message: any) => (
                <div
                  key={message.id}
                  className="p-2 m-2 bg-gray-200 rounded-2xl"
                >
                  <span className="font-bold">
                    {message.receiverId == localStorage.getItem("userId")
                      ? selectedUser
                        ? `${selectedUser.firstName}:  `
                        : ""
                      : "You:  "}
                  </span>
                  <span>{message.message}</span>
                </div>
              ))}
            </div>
            {selectedUser && (
              <div className="h-15 p-1 border border-t-gray-500 border-b-0 border-x-0 flex justify-around">
                <input
                  className="w-5/6 px-1 py-3"
                  type="text"
                  placeholder="Enter your message"
                  value={message}
                  ref={messageInputRef}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button
                  className="my-1 px-6 rounded-full bg-[#D40801] text-white cursor-pointer"
                  onClick={handleMessageSubmit}
                >
                  Send
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
