export const navMenuItemsHomeowners = [
  { name: "Home", route: "/home", classes: "px-2 py-2 text-white" },
  {
    name: "Find Tradepersons",
    route: "/engineers",
    classes: "px-2 py-2 text-white",
  },
  { name: "Post a job", route: "/post-job", classes: "px-2 py-2 text-white" },
  {
    name: "Register as an Tradeperson",
    route: "/engineer/register",
    classes: "px-3 py-2 text-white h-10",
  },
];
export const navMenuItemsHomeownersLoggedIn = [
  { name: "Home", route: "/home", classes: "px-2 py-2 text-white" },
  {
    name: "Find Tradepersons",
    route: "/engineers",
    classes: "px-2 py-2 text-white",
  },
  { name: "Post a job", route: "/post-job", classes: "px-2 py-2 text-white" },
  { name: "Change Account Settings", route: "/change-account-settings", classes: "px-2 py-2 text-white" },
];
export const navMenuItemsEngineers = [
  { name: "Home", route: "/home", classes: "px-2 py-2 text-white" },
  { name: "Find Jobs", route: "/jobs", classes: "px-2 py-2 text-white" },
  { name: "Change Account Settings", route: "/change-account-settings", classes: "px-2 py-2 text-white" },
];

export const footerMenuItems = [
  { name: "Search Tradepersons", route: "/engineers" },
  { name: "Login / Register", route: "/login"},
  { name: "Post A Job", route: "/post-job" },
  // { name: "FAQ" },
  // { name: "Contact Us" },
];

export const footerInfo = {
  phone: "07895 674275",
  address: "28 Westoe Road, London, N9 0SH",
  email: "support@findengineers.co.uk",
};

export const weekDays = [
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
  { id: 7, name: "Sunday" },
];

export const servicesList = [
  { id: 1, name: "Service 1" },
  { id: 2, name: "Service 2" },
  { id: 3, name: "Service 3" },
  { id: 4, name: "Service 4" },
];

export const categoriesList = [
  { id: 1, name: "Category 1" },
  { id: 2, name: "Category 2" },
  { id: 3, name: "Category 3" },
  { id: 4, name: "Category 4" },
  { id: 5, name: "Category 5" },
];

export const allTradesList = [
  {
    id: 1,
    name: "Architecture",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 2,
    name: "Bathroom Fitting",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 3,
    name: "Bricklaying",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 4,
    name: "CAD/Drawings",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 5,
    name: "Carpentry",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 6,
    name: "Carpets & Lino",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 7,
    name: "Central Heating",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 8,
    name: "Conservatories",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 9,
    name: "Conversions - General",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 10,
    name: "Damp Proofing",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 11,
    name: "Decking",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 12,
    name: "Demolition",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 13,
    name: "Driveways (Paved & Loose Surface)",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 14,
    name: "Driveways (Tarmac Surfaces)",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 15,
    name: "Electrical",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 16,
    name: "Extensions",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 17,
    name: "Fascias & Soffits",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 18,
    name: "Fencing",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 19,
    name: "Fireplaces & Flues",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 20,
    name: "Garden Maintenance",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 21,
    name: "Gas Work",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 22,
    name: "Groundwork & Foundations",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 23,
    name: "Guttering",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 24,
    name: "Handyman",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 25,
    name: "Hard Flooring",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 26,
    name: "Insulation",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 27,
    name: "Joinery & Cabinet Making",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 28,
    name: "Kitchen Fitting",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 29,
    name: "Landscaping",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 30,
    name: "Locksmiths",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 31,
    name: "Loft Conversions",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 32,
    name: "New Builds",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 33,
    name: "Painting & Decorating",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 34,
    name: "Plastering",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 35,
    name: "Plumbing",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 36,
    name: "Repointing",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 37,
    name: "Restoration & Refurbishment",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 38,
    name: "Roofing (Flat)",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 39,
    name: "Roofing (Pitched)",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 40,
    name: "Security Systems",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 41,
    name: "Stonemasonry",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 42,
    name: "Tiling",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 43,
    name: "Tree Surgery",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 44,
    name: "Waste Clearance",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 45,
    name: "Windows & Doors (uPVC & Metal)",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
  {
    id: 46,
    name: "Windows & Doors (Wooden)",
    description: null,
    createdAt: "2023-06-25T17:51:28.000Z",
    updatedAt: "2023-06-25T17:51:28.000Z",
    deletedAt: null,
  },
];
