import { IRoute } from "../../common/typings/routes";
import { HomePage } from "./pages/HomePage";

export const HomeRoute: IRoute = {
  title: "Home",
  path: "/home",
  component: HomePage,
  isAuthGuard: false,
  isNoAuthGuard: false
};
