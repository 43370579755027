import React from "react";

export function Step7() {
  return (
    <div className="text-center">
      <p className="text-3xl font-bold mb-4 ">Registration Completed!</p>
      <a href="/" className="block py-3 text-[#0077C0]">
        Go to homepage
      </a>
    </div>
  );
}
