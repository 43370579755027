import { IRegister } from "../typings/register";

export const registerDefaultValues: IRegister = {
    firstName: "",
    lastName: "",
    phoneNo: "",
    email: "",
    password: "",
    check1: false,
    check2: false,
    tradingName: "",
    registeredCompanyName: "",
    companyRegistrationNumber: "",
    partnersFullNames: "",
    workAddress: "",
    workAddress2: "",
    town: "",
    postcode: "",
    distance: "",
    intro: "",
    trading: "",
    selectedTrades: [],
    userType: 0
}