import { useFormik } from "formik";
import React, { useState } from "react";
import {
  registerFormData,
  setRegisterFormData,
  setRegistrationStep,
  stepNo,
} from "../../../../app-redux/auth/authSlice";
import { useAppSelector, useAppDispatch } from "../../../../app-redux/hooks";
import { CommonInputField } from "../../../../common/components/CommonInputField";
import { boolean, object, string } from "yup";

export function Step1() {
  const step = useAppSelector(stepNo);
  const dispatch = useAppDispatch();
  const data = useAppSelector(registerFormData);
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = object({
    firstName: string().required("This field is required"),
    lastName: string().required("This field is required"),
    phoneNo: string()
      .required("This field is required")
      .matches(/^[+0-9- ]*$/, "Please enter a valid phone number"),
    email: string()
      .email("Please enter a valid email")
      .required("This field is required"),
      check2: boolean().isTrue("Please accept terms and conditions to move forward")
  });

  const formik = useFormik<any>({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      userType: 0,
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      phoneNo: data.phoneNo || "",
      email: data.email || "",
      password: data.password || "",
      check1: data.check1 || false,
      check2: data.check2 || false,
    },
    onSubmit: async (values: any) => {
      const payload: any = {
        ...data,
        ...values,
      };
      dispatch(setRegisterFormData(payload));
      dispatch(setRegistrationStep(step + 1));
    },
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <p className="text-3xl font-bold mb-4 px-2">Register as an Tradesperson</p>
      <div className="text-lg px-2">
        Tradespeople.space supports quality tradespeople. Our application process is
        strict and only those who meet our high standards are accepted.
      </div>

      <div className="flex flex-wrap">
        {/* First Name */}
        <CommonInputField
          outerClass="w-full px-2 py-3"
          innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
          type="text"
          name="firstName"
          placeholder="First Name"
          value={formik.values.firstName}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          onChange={(event: any) => {
            formik.setFieldValue("firstName", event.target.value);
          }}
        />

        {/* Last Name */}
        <CommonInputField
          outerClass="w-full px-2 py-3"
          innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formik.values.lastName}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          onChange={(event: any) => {
            formik.setFieldValue("lastName", event.target.value);
          }}
        />

        {/* Phone No */}
        <CommonInputField
          outerClass="w-full px-2 py-3"
          innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
          type="text"
          name="phoneNo"
          placeholder="Phone Number"
          value={formik.values.phoneNo}
          error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
          helperText={formik.touched.phoneNo && formik.errors.phoneNo}
          onChange={(event: any) => {
            formik.setFieldValue("phoneNo", event.target.value);
          }}
        />

        {/* Email */}
        <CommonInputField
          outerClass="w-full px-2 py-3"
          innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
          type="email"
          name="email"
          placeholder="Email"
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onChange={(event: any) => {
            formik.setFieldValue("email", event.target.value);
          }}
        />

        {/* Password */}
        <CommonInputField
          outerClass="w-full px-2 py-3"
          innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="Password"
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          onChange={(event: any) => {
            formik.setFieldValue("password", event.target.value);
          }}
          isPasswordField={true}
          showPassword={showPassword}
          handleTogglePassword={handleTogglePassword}
        />

        {/* Checkbox 1 */}
        <CommonInputField
          outerClass="w-full px-2 py-3 flex"
          innerClass="py-3 px-6"
          type="checkbox"
          label="I'd like to receive Tradepersons News, Advice and Tips"
          name="check1"
          placeholder="check1"
          checked={formik.values.check1}
          onChange={(event: any) => {
            formik.setFieldValue("check1", event.target.checked);
          }}
        />

        {/* Checkbox 2 */}
        <CommonInputField
          outerClass="w-full px-2 py-3 flex"
          innerClass="py-3 px-6 relative"
          style={{ top: "-10px" }}
          type="checkbox"
          label="I agree to the terms & conditions, the Quote Tool terms & conditions and the data sharing agreement"
          name="check2"
          placeholder="check2"
          error={formik.touched.check2 && Boolean(formik.errors.check2)}
          helperText={formik.touched.check2 && formik.errors.check2}
          checked={formik.values.check2}
          onChange={(event: any) => {
            formik.setFieldValue("check2", event.target.checked);
          }}
        />

        {/* Submit part 1 */}
        <CommonInputField
          outerClass="w-full px-2 py-3 flex justify-center"
          innerClass="py-3 px-6 rounded-full bg-[#D40801] w-full md:w-2/3 xl:w-2/5 text-white text-xl font-bold cursor-pointer"
          type="button"
          value="Continue"
          onClick={() => {
            formik.submitForm();
          }}
        />
        <p className="w-full py-2 text-center">
          I have read and understood the privacy notice and cookie policy.
        </p>
      </div>
    </div>
  );
}
