import React from 'react';

interface Props {
    rating: number
}

export function RatingStars({ rating }: Props) {
  const getStarIcon = (filled: boolean) => {
    return filled ? '★' : '☆';
  };

  return (
    <div>
      {Array.from({ length: 5 }, (_, index) => (
        <span key={index} className={index < rating ? 'text-yellow-500' : 'text-gray-400'}>
          {getStarIcon(index < rating)}
        </span>
      ))}
    </div>
  );
};