import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";

interface Props {
  children: React.ReactNode;
}

export function Layout(props: Props) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <div />
        {props.children}
      </main>
      <Footer />
    </div>
  );
}
