import React from "react";
import { Redirect } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  redirectPath: string;
  path: string;
  key: number;
}

export function AuthGuard(props: Props) {
  return localStorage.getItem('token') ? <>{props.children}</> : <Redirect to={{ pathname: props.redirectPath }} />;
}
