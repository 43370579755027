import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ROUTES } from "./routes";
import { Layout } from "../common/layout/Layout";
import { NoAuthGuard } from "../guards/NoAuthGuard";
import { AuthGuard } from "../guards/AuthGuard";

export function AppRouting() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {ROUTES.map((route, i) =>
            route.isAuthGuard ? (
              <AuthGuard key={i} path={route.path} redirectPath="/login">
                {<route.component />}
              </AuthGuard>
            ) : route.isNoAuthGuard ? (
              <NoAuthGuard key={i} path={route.path} redirectPath="/home">
                {<route.component />}
              </NoAuthGuard>
            )
            : <Route key={i} path={route.path} component={route.component} />
          )}
          <Redirect to={{ pathname: "/home" }} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}
