import { IRoute } from "../../../common/typings/routes";
import { Register } from "./pages/Register";

export const RegisterRoute: IRoute = {
  title: "Register",
  path: "/engineer/register",
  component: Register,
  isAuthGuard: false,
  isNoAuthGuard: true
};
