import React, { useEffect } from "react";
import { useFormik } from "formik";
import BgBanner from "../../../assets/img-homepage-banner.png";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import {
  allPopularSearches,
  setPopularSearches,
  sethomeSearchString,
} from "../../../app-redux/main/mainSlice";
import { CommonInputField } from "../../../common/components/CommonInputField";
import { useHistory } from "react-router-dom";

export function Banner() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const popularSearches = useAppSelector(allPopularSearches);

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: {
      keyword: "",
      // location: "",
    },
    onSubmit: async (values: any) => {
      dispatch(sethomeSearchString(values.keyword));
      localStorage.getItem("userType") &&
      localStorage.getItem("userType") == "0"
        ? history.push("/jobs")
        : history.push("/engineers");
    },
  });

  useEffect(() => {
    dispatch(setPopularSearches("Plumbing, Tiler, Builder"));
  }, []);

  return (
    <>
      {/* Banner */}
      <div
        className="bg-black bg-cover bg-center text-white py-56"
        style={{ backgroundImage: `url(${BgBanner})` }}
      >
        {/* Text */}
        <div className="py-6">
          <p className="w-full flex justify-center p-3 text-center">
            Find quality local trades people reviewed by customers all across
            the UK
          </p>
          <p className="w-full flex justify-center text-3xl md:text-5xl py-3 text-center">
            Approved Trades <br /> People Near You.{" "}
          </p>
        </div>
        {/* Search Form */}
        <div className="flex flex-wrap justify-center w-3/4 md:w-full mx-auto">
          {/* Form */}
          <div className="bg-[#CD171F] bg-opacity-70 p-6 rounded-2xl md:rounded-full flex flex-wrap justify-center">
            <CommonInputField
              innerClass="m-2 py-2 px-6 rounded-full text-black"
              type="text"
              name="keyword"
              placeholder="Keyword"
              value={formik.values.keyword}
              onChange={(event: any) => {
                formik.setFieldValue("keyword", event.target.value);
              }}
            />
            {/* <CommonInputField
              innerClass="m-2 py-2 px-6 rounded-full text-black"
              type="text"
              name="location"
              placeholder="Location"
              value={formik.values.location}
              onChange={(event: any) => {
                formik.setFieldValue("location", event.target.value);
              }}
            /> */}
            <CommonInputField
              innerClass="bg-[#0077C0] m-2 py-2 px-10 rounded-full cursor-pointer"
              type="button"
              value="SEARCH"
              onClick={() => {
                formik.submitForm();
              }}
            />
          </div>
          {/* Popular Searches */}
          {/* <div className="w-full text-center p-3">
            Check Popular: {popularSearches}
          </div> */}
        </div>
      </div>
    </>
  );
}
