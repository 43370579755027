import React, { useRef } from "react";
import { stepNo } from "../../../../app-redux/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import { Step5 } from "./Step5";
import { Step7 } from "./Step7";
import { Step6 } from "./Step6";

export function Register() {
  const fileInputRef: any = useRef(null);
  const step = useAppSelector(stepNo);
  const dispatch = useAppDispatch();

  // const handleFileInputClick = () => {
  //   fileInputRef.current.click();
  // };

  return (
    <div className="mx-auto my-16 w-full lg:w-2/5 px-3 lg:px-0">
      {/* Form */}
      <div className="bg-white p-6 shadow-lg">
        {step === 1 ? (
          <Step1 />
        ) : step === 2 ? (
          <Step2 />
        ) : step === 3 ? (
          <Step3 />
        ) : step === 4 ? (
          <Step4 />
        ) : step === 5 ? (
          <Step5 />
        ) : step === 6 ? (
          <Step6 />
        ) : step === 7 ? (
          <Step7 />
        ) : (
          <></>
        )}

        {/* Old form from design */}
        {/*
          <CommonSelect
            outerClass="w-full md:w-1/2 px-2 py-3"
            innerClass={`pt-4 pb-3 px-6 rounded-full bg-[#F7F7F7] w-full  ${
              formik.values.category !== "" ? "text-black" : "text-gray-400"
            }`}
            name="category"
            value={formik.values.category}
            selectOptions={categoriesList}
            option1="Category *"
            onChange={(event: any) => {
              formik.setFieldValue("category", event.target.value);
            }}
          />
          <div className="w-full px-2 py-3">
            <textarea
              className="pt-4 pb-3 px-6 rounded-xl bg-[#F7F7F7] w-full placeholder-gray-400"
              placeholder="Please give a brief description of the work you need performed.*"
              name="description"
              id="description"
              cols={70}
              rows={6}
              value={formik.values.description}
              onChange={(event: any) => {
                formik.setFieldValue("description", event.target.value);
              }}
            ></textarea>
          </div>
          <div className="flex flex-wrap lg:flex-nowrap w-full">
            <label htmlFor="date" className="w-full lg:w-1/5 px-8 py-6 text-xl font-bold">
              Image/Logo
            </label>
            <div className="relative w-full lg:w-4/5 px-2 py-3" style={{height: "200px"}}>
              <input
                type="file"
                ref={fileInputRef}
                className={"opacity-0 absolute inset-0 w-full h-full"}
                name="file"
                placeholder="File"
                value={formik.values.file}
                onChange={(event: any) => {
                  formik.setFieldValue("file", event.target.value);
                }}
              />
              <div
                className="absolute inset-0 cursor-pointer py-3 px-6 rounded-3xl text-black bg-[#F7F7F7] flex justify-center h-full items-center mx-2 lg:mx-0"
                onClick={handleFileInputClick}
              >
                {formik.values.file ? (
                  <div>{formik.values.file}</div>
                ) : (
                  <div className="flex flex-wrap justify-center text-center">
                    <img src={CloudIcon} alt="" />
                    <p className="w-full">Browse File</p>
                    <p className="w-full">Drag and Drop files here</p>
                  </div>
                )}
              </div>
            </div>
          </div>
           */}
      </div>
    </div>
  );
}
