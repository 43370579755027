import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import {
  allJobsList,
  setBreadcrumbsItems,
  setShowViewSection,
} from "../../../app-redux/main/mainSlice";
import JobPhoto from "../../../assets/job-photo.png";
import LocationIcon from "../../../assets/icons/icon-location-red.png";
import { CommonInputField } from "../../../common/components/CommonInputField";
import { useHistory } from "react-router-dom";
import DialogBox from "../../../common/components/CommonDialog";
import moment from "moment";
import { ax } from "../../../common/api/apiClient";

interface Props {
  redirectedFrom: string;
  searchValue?: number;
  handleSearch?: any;
}

export function JobsList({ redirectedFrom, searchValue, handleSearch }: Props) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const jobsList = useAppSelector(allJobsList);

  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState<any>(null);

  const closeDialog = () => {
    setIsOpen(false);
  };

  const getJob = async (id: number) => {
    if (!localStorage.getItem("token")) {
      setShowDialog(true);
      setHeader("Error");
      setMessage("Please login first");
      // history.push("/login");
      return;
    }

    try {
      const res: any = await ax.get(`/jobs/view/${id}`);
      if (res && res.data.statusCode == 200) {
        setDetails(res.data.value[0]);
        setIsOpen(true);
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const markAsFavorite = async (id: number) => {
    if (!localStorage.getItem("token")) {
      setShowDialog(true);
      setHeader("Error");
      setMessage("Please login first");
      // history.push("/login");
      return;
    }
    const payload = {
      userId: localStorage.getItem("userId"),
      favId: id,
    };
    try {
      const res: any = await ax.post(`/jobs/markAsFavorite`, payload);
      if (res && res.data.statusCode == 200) {
        setShowDialog(true);
        setHeader("Success");
        setMessage("Successfully Marked Job as Favorite");
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
      //errorData.error
    }
  };

  const removeFromFavorites = async (favId: number) => {
    if (!localStorage.getItem("token")) {
      setShowDialog(true);
      setHeader("Error");
      setMessage("Please login first");
      // history.push("/login");
      return;
    }

    try {
      const res: any = await ax.delete(
        `/jobs/removeFromFavorites/${localStorage.getItem("userId")}/${favId}`
      );
      if (res && res.data.statusCode == 200) {
        setShowDialog(true);
        setHeader("Success");
        setMessage("Successfully Removed Job from Favorites");
      }
    } catch (error: any) {
      setShowDialog(true);
      setHeader("Error");
      setMessage(error.response.data.error);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false); // Hide the dialog box
    handleSearch(Number(searchValue) + 1);
  };

  return (
    <>
      <DialogBox
        isOpen={showDialog}
        header={header}
        message={message}
        onClose={handleCloseDialog}
      />
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow w-2/3 lg:w-1/2">
            <h2 className="text-lg font-bold mb-4 text-center">Job Details</h2>
            <hr />
            <div className="max-h-80 overflow-y-auto">
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Name: </p>
                  <p className="w-1/2 px-1">{details.name}</p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Phone: </p>
                  <p className="w-1/2 px-1">{details.phoneNo}</p>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Email: </p>
                  <p className="w-1/2 px-1">{details.email}</p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Address: </p>
                  <p className="w-1/2 px-1">{details.address}</p>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">City: </p>
                  <p className="w-1/2 px-1">{details.city}</p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">State: </p>
                  <p className="w-1/2 px-1">{details.state}</p>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Service Type: </p>
                  <p className="w-1/2 px-1">{details.serviceTypeName}</p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Description: </p>
                  <p className="w-1/2 px-1">{details.description}</p>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Date: </p>
                  <p className="w-1/2 px-1">
                    {moment(details.date).format("YYYY-MM-DD")}
                  </p>
                </div>
                <div className="w-full md:w-1/2 flex m-3">
                  <p className="font-bold w-1/2 px-1">Day: </p>
                  <p className="w-1/2 px-1">{details.day}</p>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex justify-center">
              <button
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                onClick={closeDialog}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="w-full flex flex-wrap justify-center">
        {jobsList.map((item, index) => {
          return (
            <div
              key={index}
              className="bg-white m-4 p-6 rounded-2xl flex flex-wrap lg:flex-nowrap w-full lg:w-3/4"
            >
              {/* <img src={JobPhoto} alt="" className="rounded-2xl w-full lg:w-1/3" /> */}
              <div className="w-full flex justify-center md:justify-between flex-wrap md:flex-nowrap items-center">
                <div className="p-8">
                  <p className="text-2xl font-bold">{item.name}</p>
                  <p className="text-xl">{item.email}</p>
                  <p className="text-xl">{item.address}</p>
                </div>
                <div className={item.isFavorite ? "py-8 px-4" : "py-8 px-16"}>
                  <div className="text-xl flex">
                    <img src={LocationIcon} alt="" />{" "}
                    <p className="pl-2">{item.city}</p>
                  </div>
                  <CommonInputField
                    innerClass="bg-[#0077C0] mx-2 my-4 py-2 px-10 rounded-full cursor-pointer text-white"
                    type="button"
                    value="View Job"
                    onClick={() => {
                      getJob(item.id);
                      // dispatch(
                      //   setBreadcrumbsItems({
                      //     jobType: item.type,
                      //     jobName: item.name,
                      //   })
                      // );
                      // if (redirectedFrom == "featured") {
                      //   dispatch(setShowViewSection(true));
                      //   history.push("/jobs");
                      // } else {
                      //   dispatch(setShowViewSection(true));
                      // }
                    }}
                  />
                  {redirectedFrom == "featured" ? (
                    <></>
                  ) : (
                    <CommonInputField
                      innerClass="bg-[#CD171F] mx-2 my-2 py-2 px-10 rounded-full cursor-pointer text-white"
                      type="button"
                      value={
                        item.isFavorite
                          ? "Remove From Favorites"
                          : "Mark As Favorite"
                      }
                      onClick={() => {
                        item.isFavorite
                          ? removeFromFavorites(item.id)
                          : markAsFavorite(item.id);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
