import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IRegister } from "../../common/typings/register";
import { registerDefaultValues } from "../../common/utils/defaultValues";

export interface AuthState {
  registerForm: IRegister;
  stepNo: number;
  isLoggedIn: boolean;
  isEngineer: boolean;
}

const initialState: AuthState = {
  registerForm: registerDefaultValues,
  stepNo: 1,
  isLoggedIn: false,
  isEngineer: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRegisterFormData: (state: any, value) => {
      state.registerForm = value.payload;
    },
    setRegistrationStep: (state: any, value) => {
      state.stepNo = value.payload;
    },
    setIsLoggedIn: (state: any, value) => {
      state.isLoggedIn = value.payload.isLoggedIn;
      state.isEngineer = value.payload.isEngineer;
    },
  },
  extraReducers: (builder) => {},
});

export const { setRegisterFormData, setRegistrationStep, setIsLoggedIn } = authSlice.actions;
export const registerFormData = (state: RootState) => state.auth.registerForm;
export const stepNo = (state: RootState) => state.auth.stepNo;
export const isUserLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const isUserEngineer = (state: RootState) => state.auth.isEngineer;
