import React, { useState } from "react";
import { CommonInputField } from "../../../../common/components/CommonInputField";
import { object, string } from "yup";
import { useFormik } from "formik";
import DialogBox from "../../../../common/components/CommonDialog";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import { loginPageRedirectedFrom } from "../../../../app-redux/main/mainSlice";
import { setIsLoggedIn } from "../../../../app-redux/auth/authSlice";
import { ax } from "../../../../common/api/apiClient";

export function Login() {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  const history = useHistory();
  const redirectedFrom = useAppSelector(loginPageRedirectedFrom);

  const validationSchema = object({
    email: string().required("This field is required"),
    password: string().required("This field is required"),
  });

  const formik = useFormik<any>({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values: any) => {
      try {
        const res: any = await ax.post(`/users/login`, values);
        if (res && res.data.statusCode == 200) {
          setShowDialog(true);
          setHeader("Success");
          setMessage(res.data.message);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("userType", res.data.user.userType);
          localStorage.setItem("userId", res.data.user.id);
          dispatch(
            setIsLoggedIn({
              isLoggedIn: true,
              isEngineer: res.data.user.userType == 0 ? true : false,
            })
          );
        }
      } catch (error: any) {
        setShowDialog(true);
        setHeader("Error");
        setMessage(error.response.data.error);
        //errorData.error
      }
    },
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseDialog = () => {
    setShowDialog(false); // Hide the dialog box
    if (header == "Success") {
      if (
        localStorage.getItem("userType") == "1" &&
        redirectedFrom == "post-job"
      ) {
        history.push("/post-job");
        return;
      }
      history.push("/home");
      // location.reload();
    }
  };

  return (
    <>
      <DialogBox
        isOpen={showDialog}
        header={header}
        message={message}
        onClose={handleCloseDialog}
      />
      <div className="mx-auto my-16 w-full lg:w-2/5 px-3 lg:px-0">
        {/* Form */}
        <div className="bg-white p-6 shadow-lg">
          <p className="text-3xl font-bold mb-4 px-2 text-center">Log In</p>
          <div className="flex flex-wrap">
            {/* Email */}
            <CommonInputField
              outerClass="w-full px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type="text"
              name="email"
              placeholder="Email"
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onChange={(event: any) => {
                formik.setFieldValue("email", event.target.value);
              }}
            />

            {/* Password */}
            <CommonInputField
              outerClass="w-full px-2 py-3"
              innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onChange={(event: any) => {
                formik.setFieldValue("password", event.target.value);
              }}
              isPasswordField={true}
              showPassword={showPassword}
              handleTogglePassword={handleTogglePassword}
            />

            <CommonInputField
              outerClass="w-full px-2 py-3 flex justify-center"
              innerClass="py-3 px-6 rounded-full bg-[#D40801] w-full md:w-2/3 xl:w-2/5 text-white text-xl font-bold cursor-pointer"
              type="button"
              value="Login"
              onClick={() => {
                formik.submitForm();
              }}
            />
            <a
              className="w-full text-center text-blue-500 underline"
              href="/register"
            >
              Register as a Homeowner
            </a>
            <a
              className="w-full text-center text-blue-500 underline"
              href="/engineer/register"
            >
              Register as an Tradesperson
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
