import React from "react";
import HomeownersImg from "../../../assets/img-homeowners.png";
import HomeownersIcon from "../../../assets/icon-homeowners.png";
import EngineersImg from "../../../assets/img-engineers.png";
import EngineersIcon from "../../../assets/icon-engineers.png";
import { useHistory } from "react-router";
import { CommonInputField } from "../../../common/components/CommonInputField";

export function Post() {
  const history = useHistory();

  return (
    <div className="flex flex-wrap md:flex-nowrap pt-3">
      {/* Homeowners */}
      <div className="relative w-full md:mr-2">
        <img
          src={HomeownersImg}
          alt="Background Image"
          className="w-full h-full object-cover"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-[#0077C0] bg-opacity-90 text-white">
          <div className="flex justify-center h-full items-center">
            <div className="text-center">
              <img src={HomeownersIcon} alt="" className="mx-auto" />
              <p className="text-4xl font-bold py-3 ">Homeowners</p>
              <CommonInputField
                innerClass="bg-[#CD171F] m-2 py-2 px-10 rounded-full cursor-pointer"
                type="button"
                value="Post a Job"
                onClick={() => {
                  history.push("/post-job");
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Engineers/Trades */}
      <div className="relative w-full mt-2 md:mt-0 md:ml-2">
        <img
          src={EngineersImg}
          alt="Background Image"
          className="w-full h-full object-cover"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-[#CD171F] bg-opacity-90 text-white">
          <div className="flex justify-center h-full items-center">
            <div className="text-center">
              <img src={EngineersIcon} alt="" className="mx-auto" />
              <p className="text-4xl font-bold py-3 w-full text-center">
                Tradepersons
              </p>
              <CommonInputField
                innerClass="bg-[#0077C0] m-2 py-2 px-10 rounded-full cursor-pointer"
                type="button"
                value="Register"
                onClick={() => {
                  history.push("/engineer/register");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
