import React from "react";
import { CommonInputField } from "../../../../common/components/CommonInputField";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import {
  registerFormData,
  setRegisterFormData,
  setRegistrationStep,
  stepNo,
} from "../../../../app-redux/auth/authSlice";
import { useFormik } from "formik";
import { number, object, string } from "yup";

export function Step3() {
  const step = useAppSelector(stepNo);
  const data = useAppSelector(registerFormData);
  const dispatch = useAppDispatch();
  const validationSchema = object({
    workAddress: string().required("This field is required"),
    workAddress2: string().required("This field is required"),
    town: string().required("This field is required"),
    postcode: string().required("This field is required"),
    distance: number().required("This field is required"),
  })

  const formik = useFormik<any>({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      workAddress: data.workAddress || "",
      workAddress2: data.workAddress2 || "",
      town: data.town || "",
      postcode: data.postcode || "",
      distance: data.distance || "",
    },
    onSubmit: async (values: any) => {
      const payload: any = {
        ...data,
        ...values,
      };
      dispatch(setRegisterFormData(payload));
      dispatch(setRegistrationStep(step + 1));
    },
  });
  return (
    <div>
      <p className="text-3xl font-bold mb-4">What's your work address?</p>

      <CommonInputField
        outerClass="w-full py-3"
        innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
        type="text"
        name="workAddress"
        placeholder="Work adress"
        value={formik.values.workAddress}
        error={formik.touched.workAddress && Boolean(formik.errors.workAddress)}
        helperText={formik.touched.workAddress && formik.errors.workAddress}
        onChange={(event: any) => {
          formik.setFieldValue("workAddress", event.target.value);
        }}
      />
      <CommonInputField
        outerClass="w-full py-3"
        innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
        type="text"
        name="workAddress2"
        placeholder="Work adress line 2"
        value={formik.values.workAddress2}
        error={formik.touched.workAddress2 && Boolean(formik.errors.workAddress2)}
        helperText={formik.touched.workAddress2 && formik.errors.workAddress2}
        onChange={(event: any) => {
          formik.setFieldValue("workAddress2", event.target.value);
        }}
      />
      <CommonInputField
        outerClass="w-full py-3"
        innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
        type="text"
        name="town"
        placeholder="Town"
        value={formik.values.town}
        error={formik.touched.town && Boolean(formik.errors.town)}
        helperText={formik.touched.town && formik.errors.town}
        onChange={(event: any) => {
          formik.setFieldValue("town", event.target.value);
        }}
      />
      <CommonInputField
        outerClass="w-full py-3"
        innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
        type="text"
        name="postcode"
        placeholder="Postcode"
        value={formik.values.postcode}
        error={formik.touched.postcode && Boolean(formik.errors.postcode)}
        helperText={formik.touched.postcode && formik.errors.postcode}
        onChange={(event: any) => {
          formik.setFieldValue("postcode", event.target.value);
        }}
      />
      <div className="p-2">
        What is the maximum distance (miles) you are willing to travel for work?
      </div>

      <CommonInputField
        outerClass="w-full py-3"
        innerClass="py-3 px-6 rounded-full text-black bg-[#F7F7F7] w-full"
        type="number"
        name="distance"
        value={formik.values.distance}
        error={formik.touched.distance && Boolean(formik.errors.distance)}
        helperText={formik.touched.distance && formik.errors.distance}
        onChange={(event: any) => {
          formik.setFieldValue("distance", event.target.value);
        }}
      />
      <div className="p-2">
        This will determine which leads we send you. Later you can create your
        custom working area on a map.
      </div>
      {/* buttons */}
      <div className="inline-flex w-full">
        <CommonInputField
          outerClass="w-2/5 px-2 py-3 flex justify-center"
          innerClass="py-3 px-6 rounded-full border border-[#D40801] text-[#D40801] w-full text-xl font-bold cursor-pointer"
          type="button"
          value="Back"
          onClick={() => {
            dispatch(setRegistrationStep(step - 1));
          }}
        />
        <CommonInputField
          outerClass="w-3/5 px-2 py-3 flex justify-center"
          innerClass="py-3 px-6 rounded-full bg-[#D40801] w-full text-white text-xl font-bold cursor-pointer"
          type="button"
          value="Continue"
          onClick={() => {
            formik.submitForm();
          }}
        />
      </div>
    </div>
  );
}
