import React from "react";
import { CommonInputField } from "../../../common/components/CommonInputField";
import LocationIcon from "../../../assets/icons/icon-location-red.png";
import PhoneIcon from "../../../assets/icons/icon-phone2.png";
import JobPhoto from "../../../assets/job-photo.png";
import MapPhoto from "../../../assets/img-map.png";
import Arrow from "../../../assets/icons/icon-pagination-arrow.png";
import { useFormik } from "formik";
import { useAppSelector } from "../../../app-redux/hooks";
import { breadcrumbs } from "../../../app-redux/main/mainSlice";

export function ViewJob() {
  const breadcrumbsItems = useAppSelector(breadcrumbs);

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: {
      description: "",
    },
    onSubmit: async (values: any) => {
      console.log(formik, values);
      // add submit form api here and call reset function after response
      formik.resetForm();
    },
  });

  return (
    <div>
      <div className="flex w-full lg:w-5/6 xl:w-3/4 mx-auto mt-16 px-8 lg:px-16 text-xs md:text-md xl:text-lg">
        <p>Home</p>
        <img src={Arrow} alt="" className="mx-3 lg:mx-4 py-1 xl:py-2" />
        <p>Search Results</p>
        <img src={Arrow} alt="" className="mx-3 lg:mx-4 py-1 xl:py-2" />
        <p>{breadcrumbsItems.jobType ? breadcrumbsItems.jobType : "Sample Text"}</p>
        <img src={Arrow} alt="" className="mx-3 lg:mx-4 py-1 xl:py-2" />
        <p className="text-[#D40801]">{breadcrumbsItems.jobName ? breadcrumbsItems.jobName : "Sample Text"}</p>
      </div>
      {/* Info Section */}
      <div className="flex flex-wrap lg:flex-nowrap justify-center w-full lg:w-5/6 xl:w-3/4 mx-auto md:px-10 lg:px-0">
        <div className="mx-auto mt-16 lg:my-16 w-full lg:w-3/5 xl:w-2/3">
          <div className="bg-white mx-6 lg:mx-16 p-6 lg:p-12 xl:p-16 shadow-xl">
            <p className="text-3xl md:text-4xl font-bold">Plumbing London, UK</p>
            <div className="text-lg md:text-xl flex pt-3">
              <img src={LocationIcon} alt="" />{" "}
              <p className="pl-2">
                London, UK <u className="text-[#D40801]">See map</u>
              </p>
            </div>
            <p className="pt-16 text-md md:text-lg">
              Get your all plumbing work done in matter of hours!
            </p>
            <img src={JobPhoto} alt="" className="w-full h-full mt-8" />
          </div>
        </div>
        <div className="mx-auto my-8 lg:my-16 w-full lg:w-2/5 xl:w-1/3">
          <div className="bg-white mx-6 lg:ml-0 p-6 lg:mr-6 xl:mr-10 shadow-lg text-center">
            <div className="flex">
              <CommonInputField
                innerClass="bg-[#D40801] mx-auto my-4 py-2 px-10 rounded-full text-white text-2xl md:text-xl font-bold"
                type="button"
                value="Edmilson Pereira"
              />
            </div>
            <p className="text-lg text-gray-400 py-1">Member since 6 months</p>
            <div className="flex mt-8">
              <img
                src={PhoneIcon}
                alt=""
                className="mx-auto p-3 bg-[#0077C0] rounded-full"
              />
            </div>
            <p className="text-3xl  md:text-2xl font-bold text-[#0077C0] py-3">966148174</p>
            <div className="flex">
              <CommonInputField
                innerClass="mx-auto my-4 py-2 px-16 rounded-full text-gray-400 text-lg border border-2"
                type="button"
                value="View Profile"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Location Section */}
      <div className="w-full lg:w-5/6 xl:w-3/4 mx-auto px-8 md:px-16">
        <p className="text-2xl md:text-3xl lg:text-4xl font-bold mx-4 lg:mx-8 mb-8">Location</p>
        <img src={MapPhoto} alt="" className="w-full h-full" />
        <div className="w-full lg:w-3/4 xl:w-1/2 text-md md:text-lg flex justify-evenly my-6">
          <p>ID: 5988</p>
          <p>|</p>
          <p>Published: July 23, 2022</p>
          <p>|</p>
          <p>Views: 113</p>
        </div>
      </div>
      {/* Send Message Section */}
      <div className="w-full lg:w-5/6 xl:w-3/4 mx-auto my-8 px-8 md:px-28 xl:px-32">
        <p className="text-2xl md:text-3xl lg:text-4xl font-bold mb-8">Send Message</p>
        <div className="w-full py-3">
          <textarea
            className="pt-4 pb-3 px-6 rounded-xl bg-[#F6F6F6] w-full placeholder-gray-400"
            placeholder="I'm interested in Plumbing London, UK"
            name="description"
            id="description"
            cols={70}
            rows={8}
            value={formik.values.description}
            onChange={(event: any) => {
              formik.setFieldValue("description", event.target.value);
            }}
          ></textarea>
        </div>
        <CommonInputField
          outerClass="w-full px-2 py-2 lg:py-3 flex justify-center"
          innerClass="py-3 px-12 rounded-full bg-[#D40801] w-1/2 md:w-1/3 lg:w-2/5 text-white text-xl lg:text-2xl font-bold"
          type="button"
          value="Send"
          onClick={() => {
            formik.submitForm();
          }}
        />
      </div>
    </div>
  );
}
