import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import {
  allTestimonials,
  setTestimonials,
} from "../../../app-redux/main/mainSlice";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { RatingStars } from "../../../common/utils/ratingStars";

const responsiveSettings = [
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
      breakpoint: 500,
      settings: {
          slidesToShow: 2,
          slidesToScroll: 2
      }
  },
  {
      breakpoint: 200,
      settings: {
          slidesToShow: 1,
          slidesToScroll: 1
      }
  }
];

export function Testimonials() {
  const dispatch = useAppDispatch();
  const testimonials = useAppSelector(allTestimonials);

  useEffect(() => {
    const list = [
      {
        id: 1,
        name: "Leslie Knowles",
        location: "Essex, UK",
        rating: 5,
        text:
          "I needed to find a trustworthy company and I wasn’t disappointed. Within hours of posting my request for somebody to come and look at my broken down boiler I had been contacted 3 times. Today the engineers came to install a new boiler.",
      },
      {
        id: 2,
        name: "Leslie Knowles",
        location: "Essex, UK",
        rating: 4,
        text:
          "I needed to find a trustworthy company and I wasn’t disappointed. Within hours of posting my request for somebody to come and look at my broken down boiler I had been contacted 3 times. Today the engineers came to install a new boiler.",
      },
      {
        id: 3,
        name: "Leslie Knowles",
        location: "Essex, UK",
        rating: 5,
        text:
          "I needed to find a trustworthy company and I wasn’t disappointed. Within hours of posting my request for somebody to come and look at my broken down boiler I had been contacted 3 times. Today the engineers came to install a new boiler.",
      },
      {
        id: 4,
        name: "Leslie Knowles",
        location: "Essex, UK",
        rating: 5,
        text:
          "I needed to find a trustworthy company and I wasn’t disappointed. Within hours of posting my request for somebody to come and look at my broken down boiler I had been contacted 3 times. Today the engineers came to install a new boiler.",
      },
      {
        id: 5,
        name: "Leslie Knowles",
        location: "Essex, UK",
        rating: 4,
        text:
          "I needed to find a trustworthy company and I wasn’t disappointed. Within hours of posting my request for somebody to come and look at my broken down boiler I had been contacted 3 times. Today the engineers came to install a new boiler.",
      },
    ];
    dispatch(setTestimonials(list));
  }, []);

  return (
    <div className="bg-[#F2F2F2] py-12">
      {/* Heading */}
      <p className="text-lg p-2 text-center">What Homeowners Say</p>
      <p className="text-4xl font-bold text-center">Testimonials</p>

      {/* Slider */}
      <div className="mx-auto my-12 w-3/4">
        <Slide
          slidesToScroll={2}
          slidesToShow={2}
          indicators={true}
          responsive={responsiveSettings}
        >
          {testimonials.map((item, index) => {
            return (
              <div key={item.id} className="bg-white mx-2 py-3 px-8 h-full">
                <p className="text-[#0077C0] text-8xl">"</p>
                <p className="px-2 pb-4">{item.text}</p>
                <hr className="border-b-2 border-gray-100" />
                <div className="px-2 py-4">
                  <p className="text-xl font-bold py-1">{item.name}</p>
                  <div className="py-1">
                    <RatingStars rating={item.rating} />
                  </div>
                  <p className="py-1">{item.location}</p>
                </div>
              </div>
            );
          })}
        </Slide>
      </div>
    </div>
  );
}
